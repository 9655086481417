import { QuickLinkCardProps } from "../../components/quickLinkCard/QuickLinkCard";
import { CalendarIcon, ClipboardIcon, FolderIcon } from "../../styles/icons";
import DollarBrokenIcon from "../../styles/icons/DollarBrokenIcon";
import GraphIcon from "../../styles/icons/GraphIcon";
import { API_CONSTANTS } from "./envApiConstants";
import { NAV_TAGS } from "./navTagConstants";
import { EXTERNAL_SITE_LINKS, URL_CONSTANTS } from "./urlConstants";

export const MEGA_SEARCH_MENU_CONSTANTS = {
  workforceSalary: {
    TITLE: "EXPLORE WORKFORCE & SALARY DATA",
  },
  state: {
    TITLE: "EXPLORE STATE DATA",
  },
  education: {
    TITLE: "EXPLORE EDUCATION DATA",
  },
  county: {
    TITLE: "EXPLORE COUNTY DATA",
  },
  city: {
    TITLE: "EXPLORE CITY DATA",
  },
  localDistricts: {
    TITLE: "EXPLORE LOCAL DISTRICTS DATA",
  },
};

export const WORKFORCE_SALARY_QUICK_LINK_CARDS: QuickLinkCardProps[] = [
  {
    title: "Employee Pay Rates",
    imgLink:
      "https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/TransparentIdahoLogos/Picture+Updates+12.26.2023/Employee_Pay_Rate_Report%5B1%5D.jpg",
    description:
      "The Employee Pay Rates report provides access to the compensation details of over 35,000 state employees. Here, citizens can explore...",
    buttonNavigatorLink: URL_CONSTANTS.WORKFORCE_SALARY,
    navTag: NAV_TAGS.EMPLOYEE_PAY_RATES,
  },
  {
    title: "Employee Count",
    imgLink: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-employee-count.png",
    description:
      "The Employee Count Report is a resource offering insights into the ongoing employment numbers of each agency and the overall state.",
    buttonNavigatorLink: URL_CONSTANTS.WORKFORCE_SALARY,
    navTag: NAV_TAGS.EMPLOYEE_COUNT,
  },
  {
    title: "Workforce Tenure",
    imgLink:
      API_CONSTANTS.imageBucket + "/ui-images/sco-ui-employee-pay-rates.png",
    description:
      "The Workforce Tenure Report provides insight into the duration of service and tenure trends for state employees.",
    buttonNavigatorLink: URL_CONSTANTS.WORKFORCE_SALARY,
    navTag: NAV_TAGS.TENURE,
  },
];

export const STATE_QUICK_LINK_CARDS: QuickLinkCardProps[] = [
  {
    title: "Overview",
    imgLink: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-downtown.png",
    description:
      "Transparent Idaho provides an in-depth look at the inner workings of state government by allowing citizens to...",
    buttonNavigatorLink: URL_CONSTANTS.STATE,
    navTag: NAV_TAGS.STATE_ABOUT,
  },
  {
    title: "State Agencies",
    imgLink: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-capital.png",
    description:
      "Interested in learning more about a specific state agency? Dive into the table below to filter on specific areas of...",
    buttonNavigatorLink: URL_CONSTANTS.STATE,
    navTag: NAV_TAGS.STATE_AGENCIES,
  },
];
export const STATE_SIMPLE_LINKS = [
  {
    title: "State Agreements and Contracts",
    url: EXTERNAL_SITE_LINKS.MOU,
    Icon: ClipboardIcon,
    externalUrl: true,
  },
  {
    title: "Vendor Payments",
    url: "/VendorPayments",
    Icon: DollarBrokenIcon,
    externalUrl: false,
  },
  {
    title: "Public Record Request",
    url: URL_CONSTANTS.PUBLIC_RECORD_REQUEST,
    Icon: FolderIcon,
    externalUrl: false,
  },
  {
    title: "Annual Comprehensive Financial Report",
    url: EXTERNAL_SITE_LINKS.ANNUAL_COMPREHENSIVE_FINANCIAL_REPORT,
    Icon: GraphIcon,
    externalUrl: true,
  },
  {
    title: "Schedule of Expenditures of Financial Reports",
    url: EXTERNAL_SITE_LINKS.SCHEDULE_OF_EXP_OF_FIN_REPORTS,
    Icon: CalendarIcon,
    externalUrl: true,
  },
];

export const EDUCATION_QUICK_LINK_CARDS: QuickLinkCardProps[] = [
  {
    title: "About Education Data",
    imgLink: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-4.png",
    description:
      "Through a partnership with the Idaho State Board of Education, the Idaho State Department of Education, and the Idaho...",
    buttonNavigatorLink: URL_CONSTANTS.EDUCATION,
    navTag: NAV_TAGS.EXPLORE_EDUCATION_DATA,
  },
  {
    title: "Year Over Year Education Financials",
    imgLink: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-year-over-year.png",
    description:
      "Idaho public schools are funded primarily from state general funds and are...",
    buttonNavigatorLink: URL_CONSTANTS.EDUCATION,
    navTag: NAV_TAGS.EDUCATION_YEAR_OVER_YEAR,
  },
  {
    title: "Education Salary Costs",
    imgLink:
      API_CONSTANTS.imageBucket + "/ui-images/sco-ui-education-salary.png",
    description:
      "This report includes position and salary information for education provider employees in the state of Idaho.",
    buttonNavigatorLink: URL_CONSTANTS.EDUCATION,
    navTag: NAV_TAGS.EDUCATION_SALARY_COSTS,
  },
];

export const COUNTY_QUICK_LINK_CARDS: QuickLinkCardProps[] = [
  {
    title: "About County Data",
    imgLink:
      "https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/TransparentIdahoLogos/Picture+Updates+12.26.2023/About_County_Data%5B1%5D.jpeg",
    description:
      "The Local Transparency Project Team, housed in the State Controller’s Office, worked with county leadership and the...",
    buttonNavigatorLink: URL_CONSTANTS.COUNTY,
    navTag: NAV_TAGS.EXPLORE_COUNTIES_DATA,
  },
];

export const CITY_QUICK_LINK_CARDS: QuickLinkCardProps[] = [
  {
    title: "About City Data",
    imgLink:
      "https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/TransparentIdahoLogos/Picture+Updates+12.26.2023/About_City_Data_3.jpeg",
    description:
      "Cities provide an array of services to residents and businesses that are managed by a mixture of elected local...",
    buttonNavigatorLink: URL_CONSTANTS.CITY,
    navTag: NAV_TAGS.EXPLORE_CITY_DATA,
  },
  {
    title: "View All Cities",
    imgLink: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-7.png",
    description:
      "Use the search functionality to search by city. You can view each city’s...",
    buttonNavigatorLink: URL_CONSTANTS.CITY,
    navTag: NAV_TAGS.CITY_ALL_CITY_BUDGETS,
  },
  {
    title: "City Salary Costs",
    imgLink:
      API_CONSTANTS.imageBucket + "/powerbi-images/sco-report-image-6.jpg",
    description:
      "This report includes position and salary information for all city employees in the state of Idaho. Explore the interactive ...",
    buttonNavigatorLink: URL_CONSTANTS.CITY,
    navTag: NAV_TAGS.SALARY_TAB,
  },
];

export const LOCAL_DISTRICT_QUICK_LINK_CARDS: QuickLinkCardProps[] = [
  {
    title: "About Local Districts Data",
    imgLink: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-1.png",
    description:
      "Local Districts are entities of Local Government that provide specialized services to...",
    buttonNavigatorLink: URL_CONSTANTS.LOCAL_DISTRICT,
    navTag: NAV_TAGS.EXPLORE_LOCAL_DISTRICT_DATA,
  },
  {
    title: "All Local Districts",
    imgLink: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-2.png",
    description:
      "Use the search feature to find local districts that service the county. You can view each district’s...",
    buttonNavigatorLink: URL_CONSTANTS.LOCAL_DISTRICT,
    navTag: NAV_TAGS.LOCAL_DISTRICT_ALL_LOCAL_DISTRICT_BUDGETS,
  },
  {
    title: "Urban Renewal Agency’s \nAnnual Reports",
    imgLink: API_CONSTANTS.imageBucket + "/ui-images/sco-ui-urban-renewal.png",
    description:
      "Download detailed annual reports for each Urban Renewal Agency...",
    buttonNavigatorLink: URL_CONSTANTS.LOCAL_DISTRICT,
    navTag: NAV_TAGS.Local_DISTRICT_URBAN_RENEWAL,
    positionImageTop: true,
  },
];
