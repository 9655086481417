import "./breadCrumbs.scss";
import { Link } from "react-router-dom";
import { House } from "styled-icons/fa-solid";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { requestAgencyById } from "../../../../api/searchType.api";
import { useEffect, useState } from "react";

type Props = {
  urlParam?: string;
};

const BreadCrumbs = ({ urlParam }: Props) => {
  const [agencyName, setAgencyName] = useState("");

  const routes = [
    { path: "/", breadcrumb: "Home" },
    { path: "/CountyHomePage" },
    { path: "/Counties/:countyName" },
    { path: "/CityHomePage" },
    { path: "/LocalDistrictHomePage" },
    { path: "/EducationHomePage" },
    { path: "State" },
    { path: "StateAgencies" },
    { path: "StateAgencies/:agencyName" },
    { path: "Workforce&Salary" },
    { path: "PublicRecordsRequest" },
    { path: "VendorPayments" },
  ];
  const breadcrumbs = useBreadcrumbs(routes);

  useEffect(() => {
    if (!urlParam) return;

    const fetchName = async () => {
      try {
        const agency = await requestAgencyById(urlParam!);
        setAgencyName(agency[0].EntityName);
      } catch {
        console.error("Error fetching state agency name");
      }
    };

    fetchName();
  }, [urlParam]);

  //eslint-disable-next-line
  const handleCountyName = (path: string, breadcrumb: any) => {
    const testPath = path.toLowerCase();
    if (
      !testPath.includes("/education") &&
      !testPath.includes("/counties") &&
      !testPath.includes("/city") &&
      !testPath.includes("/localdistrict") &&
      !testPath.includes("/education") &&
      !testPath.includes("/state") &&
      !testPath.includes("/stateagencies") &&
      !testPath.includes("/workforce&salary") &&
      !testPath.includes("/vendorpayments") &&
      !testPath.includes("/publicrecordrequest") &&
      testPath !== "/"
    ) {
      return "Page Not Found";
    }
    if (testPath.includes("/counties/")) {
      return urlParam !== undefined ? urlParam + " County" : "...";
    } else if (testPath.includes("/localdistrict")) {
      return "Local District";
    } else if (testPath.includes("/stateagencies/")) {
      return urlParam !== undefined ? agencyName : "...";
    } else if (testPath.includes("/stateagencies")) {
      return "State Agencies";
    } else if (testPath.includes("/workforce&salary")) {
      return "Workforce & Salary";
    } else if (testPath.includes("/vendorpayments")) {
      return "Vendor Payments";
    } else if (testPath.includes("/publicrecordrequest")) {
      return "Public Record Request";
    } else {
      return breadcrumb;
    }
  };

  return (
    <nav className="breadcrumbs-container">
      <House className="house-icon" color="#5D6063" size={22} />
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <Link
          className="breadcrumbs-text"
          key={index}
          to={match.pathname}
          role="navigation"
          aria-label="breadcrumbs"
        >
          {match.pathname !== "/" && "/"}{" "}
          {handleCountyName(match.pathname, breadcrumb)}
        </Link>
      ))}
    </nav>
  );
};

export default BreadCrumbs;
