import { useRef, useState } from "react";
import HomePageVerticalCardsSection from "./sections/homePageCardsSection/HomePageVerticalCardsSection";
import QuickLinkIcon from "../../styles/icons/QuickLinkIcon";
import GraphIcon from "../../styles/icons/GraphIcon";
import {
  NAV_TAGS,
  HOME_PAGE_CARDS_DATA,
  HOME_PAGE_CONSTANTS,
  API_CONSTANTS,
  URL_CONSTANTS,
  ABOUT_SECTION_CONSTANTS,
  EXTERNAL_SITE_LINKS,
} from "../../types/constants";
import { StickyNav, QuickLinksSection } from "../../components";
import { ChevronRightCircle } from "styled-icons/boxicons-solid";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import HomePageHero from "./sections/homePageHero/HomePageHero";
import HomePageCarousel from "./sections/homePageCarousel/HomePageCarousel";
import { IdahoIcon } from "../../styles/icons";
import "./homePage.scss";

export interface HomePageVerticalCards {
  TITLE: string;
  IMAGE: string;
  DESCRIPTION: string;
  NAVIGATION_TAG: string;
  NAVIGATION_LINK: string;
  COMING_SOON?: boolean;
}

const HomePage = () => {
  const [hoveredCounty, setHoveredCounty] = useState("");
  const [cardSelected, setCardSelected] = useState(1);
  const [isReadMore, setIsReadMore] = useState(false);

  const dataWorkforceSalary: HomePageVerticalCards[] =
    HOME_PAGE_CARDS_DATA.workforceSalary;
  const dataState: HomePageVerticalCards[] = HOME_PAGE_CARDS_DATA.state;
  const dataEducation: HomePageVerticalCards[] = HOME_PAGE_CARDS_DATA.education;
  const dataCounty: HomePageVerticalCards[] = HOME_PAGE_CARDS_DATA.county;
  const dataCity: HomePageVerticalCards[] = HOME_PAGE_CARDS_DATA.city;
  const dataLocalDistrict: HomePageVerticalCards[] =
    HOME_PAGE_CARDS_DATA.localDistrict;

  const quickLinksRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const exploreDataRef = useRef<HTMLDivElement>(null);

  const renderDescription = () => {
    if (isReadMore) {
      return (
        <div className="description">
          <p className="body-medium">
            Transparent Idaho is my commitment to the citizens of Idaho. This
            powerful tool provides research capabilities, interactive charts and
            reports, and access to the people's data.
          </p>
          <br />
          <p className="body-medium">
            Collaborating with state agencies and local government entities, the
            platform provides Idahoans with unprecedented access to information
            on state, county, city, local district, and education financial and
            workforce data.
          </p>
          <br />
          <p className="body-medium">
            The platform presents the most current information available.
            However, data is refreshed at various intervals:
          </p>
          <br />
          <ul>
            <li className="body-medium">
              State employee personnel and payroll data is updated nightly.
            </li>
            <br />
            <li className="body-medium">
              State Transactional data has a two-week lag as a best practice to
              deter fraud.
            </li>
            <br />
            <li className="body-medium">
              County, city, and local district budget and audit data is
              collected annually and posted following the closing of the Central
              Registry at the end of the calendar year. The data does not always
              coincide with the current operating year.
            </li>
            <br />
            <li className="body-medium">
              Local entity and education salary reports are updated quarterly
              (currently this only applies to counties; reports for cities and
              other local districts are coming soon).
            </li>
          </ul>
          <br />
          <p className="body-medium">
            Transparent Idaho's commitment to uniform data provides citizens an
            apples-to-apples comparison regardless of budget size and/or
            complexity. Users can view the allocation of tax dollars with ease
            and efficiency, fostering a culture of accountability and informed
            civic engagement.
          </p>
        </div>
      );
    } else {
      return (
        <div className="about-description">
          <p className="body-medium">
            Transparent Idaho is my commitment to the citizens of Idaho. This
            powerful tool provides research capabilities, interactive charts and
            reports, and access to the people's data.
          </p>
          <br />
          <p className="body-medium">
            Collaborating with state agencies and local government entities, the
            platform provides Idahoans with unprecedented access to information
            on state, county, city, local district, and education financial and
            workforce data.
          </p>
          <br />
          <p className="body-medium">
            The platform presents the most current information available.
            However, data is refreshed at various intervals:
          </p>
        </div>
      );
    }
  };

  return (
    <div className="home-container" style={{ flex: 1 }}>
      <header>
        <Header />
      </header>
      <main>
        <HomePageHero
          exploreDataRef={exploreDataRef}
          hoveredCounty={hoveredCounty}
          setHoveredCounty={setHoveredCounty}
        />
        <StickyNav
          sections={[
            {
              icon: <IdahoIcon />,
              text: "About",
              ref: aboutRef,
            },
            {
              icon: <QuickLinkIcon />,
              text: "Quick Links",
              ref: quickLinksRef,
            },
            {
              icon: <GraphIcon />,
              text: "Explore Data",
              ref: exploreDataRef,
            },
          ]}
        />

        <div className="about-section" ref={aboutRef}>
          <div className="description-container" tabIndex={0}>
            <h4 className="title">About Transparent Idaho</h4>
            {renderDescription()}
            <div
              className="read-more-button"
              onClick={() => setIsReadMore((prev) => !prev)}
            >
              <p role="button">{!isReadMore ? "Read More" : "Read Less"}</p>
              <ChevronRightCircle size="20px" color="#ffffff" />
            </div>
          </div>
          <div>
            <img
              src={
                "https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/TransparentIdahoLogos/Picture+Updates+12.26.2023/About_TI_Tag_Photo%5B2%5D.jpeg"
              }
              className="image"
              alt=""
            />
          </div>
        </div>
        <span ref={quickLinksRef}>
          <QuickLinksSection
            links={[
              {
                imgSrc: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-townhall-idaho.png`,
                imgAlt: "Town hall microphone",
                title: "Townhall Idaho",
                pageUrl: EXTERNAL_SITE_LINKS.TOWN_HALL_IDAHO,
              },
              {
                imgSrc: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-public-records.png`,
                imgAlt: "File cabinet of public records",
                title: "Public Records",
                pageUrl: URL_CONSTANTS.PUBLIC_RECORD_REQUEST,
              },
              {
                imgSrc: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-state-agreements.png`,
                imgAlt: "",
                title: "State Agreements and Contracts",
                pageUrl: EXTERNAL_SITE_LINKS.MOU,
              },
              {
                imgSrc: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-image-9.png`,
                imgAlt: "",
                title: "Vendor Payments",
                pageUrl: URL_CONSTANTS.VENDOR_PAYMENTS,
              },
              {
                imgSrc: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-additional-recourses.png`,
                imgAlt: "",
                title: "Additional Resources",
                pageUrl: EXTERNAL_SITE_LINKS.ADDITIONAL_RECOURSES,
              },
            ]}
          />
        </span>
        <HomePageCarousel
          cardSelected={cardSelected}
          setCardSelected={setCardSelected}
        />
        <span className="vertical-cards-section-container">
          <div className="cards-section-wrapper">
            <div tabIndex={0} aria-label="Explore Data section">
              <div className="home-page-title-container">
                <GraphIcon />
                <h2 ref={exploreDataRef} className="title">
                  {HOME_PAGE_CONSTANTS.EXPLORE_THE_DATA}
                </h2>
              </div>
              <p className="body-large home-page-header-description">
                {
                  "Click the links below to learn about the data collected \nor to access interactive reports and visualizations"
                }
              </p>
            </div>
            <HomePageVerticalCardsSection
              sectionTitle={
                HOME_PAGE_CONSTANTS.cardSection.title.WORKFORCE_SALARY
              }
              sectionSubTitle={
                HOME_PAGE_CONSTANTS.cardSection.subtitle.WORKFORCE_SALARY
              }
              sectionTag={NAV_TAGS.WORKFORCE_SALARY}
              sectionData={dataWorkforceSalary}
              navTag={NAV_TAGS.EXPLORE_EDUCATION_DATA}
            />
            <HomePageVerticalCardsSection
              sectionTitle={HOME_PAGE_CONSTANTS.cardSection.title.STATE_DATA}
              sectionSubTitle={
                HOME_PAGE_CONSTANTS.cardSection.subtitle.STATE_DATA
              }
              sectionTag={NAV_TAGS.STATE}
              sectionData={dataState}
              navTag={NAV_TAGS.STATE_BUDGET}
            />
            <HomePageVerticalCardsSection
              sectionTitle={HOME_PAGE_CONSTANTS.cardSection.title.COUNTY_DATA}
              sectionSubTitle={HOME_PAGE_CONSTANTS.cardSection.subtitle.COUNTY}
              sectionTag={NAV_TAGS.COUNTY}
              sectionData={dataCounty}
              navTag={NAV_TAGS.EXPLORE_COUNTIES_DATA}
            />
            <HomePageVerticalCardsSection
              sectionTitle={HOME_PAGE_CONSTANTS.cardSection.title.CITY_DATA}
              sectionSubTitle={HOME_PAGE_CONSTANTS.cardSection.subtitle.CITY}
              sectionTag={NAV_TAGS.CITY}
              sectionData={dataCity}
              navTag={NAV_TAGS.EXPLORE_CITY_DATA}
            />
            <HomePageVerticalCardsSection
              sectionTitle={
                HOME_PAGE_CONSTANTS.cardSection.title.LOCAL_DISTRICT_DATA
              }
              sectionSubTitle={
                HOME_PAGE_CONSTANTS.cardSection.subtitle.LOCAL_DISTRICTS
              }
              sectionTag={NAV_TAGS.LOCAL_DISTRICT}
              sectionData={dataLocalDistrict}
              navTag={NAV_TAGS.EXPLORE_LOCAL_DISTRICT_DATA}
            />
            <HomePageVerticalCardsSection
              sectionTitle={
                HOME_PAGE_CONSTANTS.cardSection.title.EDUCATION_DATA
              }
              sectionSubTitle={
                HOME_PAGE_CONSTANTS.cardSection.subtitle.EDUCATION
              }
              sectionTag={NAV_TAGS.EDUCATION}
              sectionData={dataEducation}
              navTag={NAV_TAGS.EXPLORE_EDUCATION_DATA}
            />
          </div>
        </span>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default HomePage;
