import { FC, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

type MegaMenuLink = {
  linkTo: string;
  linkTitle: string;
  children: ReactNode;
  isActiveMenu: boolean;
  setShowMegaSearch: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveMenu: React.Dispatch<React.SetStateAction<string>>;
};

const MegaMenuLink: FC<MegaMenuLink> = ({
  linkTo,
  linkTitle,
  children,
  isActiveMenu,
  setShowMegaSearch,
  setActiveMenu,
}) => {
  const location = useLocation();
  const pathName = location.pathname;

  const handleMouseLeave = () => {
    setShowMegaSearch(() => false);
    setActiveMenu(() => "");
  };

  const handleClassName = (thisPath: string) => {
    return pathName === thisPath
      ? "navigation-link navigation-link__current-page"
      : "navigation-link";
  };

  return (
    <span onMouseLeave={() => handleMouseLeave()}>
      <h6
        onMouseEnter={() => {
          setShowMegaSearch(() => true);
          setActiveMenu(() => linkTo);
        }}
      >
        <Link
          onClick={() => setShowMegaSearch(false)}
          onFocus={() => {
            setShowMegaSearch(() => true);
            setActiveMenu(() => linkTo);
          }}
          to={linkTo}
          className={handleClassName(linkTo)}
        >
          <div
            className="mega-menu-link"
            aria-current={isActiveMenu ? "true" : "false"}
          >
            {linkTitle}
          </div>
        </Link>
      </h6>
      {children}
    </span>
  );
};

export default MegaMenuLink;
