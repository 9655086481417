import { useEffect, useState } from "react";
import {
  EntityListCard,
  LocalDistrictSearchDropDown,
  SearchDropDownComponent,
  YearTagPill,
} from "../../../components";
import {
  EntityFilter,
  EntityFinancialFilter,
} from "../../../types/interfaces/entityInterfaces";
import {
  getCountyEntitiesByEntityTypeID,
  getCountyEntityList,
} from "../../countyPage/countyPage.service";
import "./localDistrictSearchComponent.scss";
import { CheveronLeft, CheveronRight } from "styled-icons/zondicons";
import ReportSectionComponent from "../../../components/reportSection/ReportSectionComponent";
import { models } from "powerbi-client";
import {
  requestFiscalYearList,
  requestLocalDistrictSearch,
} from "../../../api/searchType.api";
import { API_CONSTANTS, ENTITY_TYPE_IDS } from "../../../types/constants";
import { handleTypeIcon } from "../../../types/enums/iconsEnum";
import { LOCAL_DISTRICT_SPECIFIC_REPORTS } from "../../../types/constants/powerBiConstants";

const LocalDistrictSearchComponent = () => {
  const [entitiesForSearch, setEntitiesForSearch] = useState<EntityFilter[]>();
  const [selectedEntity, setSelectedEntity] = useState<EntityFilter>();
  const [selectedEntityName, setSelectedEntityName] = useState<any>();
  const [entityNameList, setEntityNameList] = useState<any[]>();
  const [isLoadingNarrative, setIsLoadingNarrative] = useState<boolean>(true);
  const [entityNarrative, setEntityNarrative] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [fiscalYears, setFiscalYears] = useState<string[]>([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<string>("2024");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [countyEntities, setCountyEntities] = useState<any>();
  const [entityDataByYear, setEntityDataByYear] = useState<any>();
  const [showDataByYear, setShowDataByYear] = useState<boolean>(false);
  const [indexForTab, setIndexForTab] = useState<number>(0);
  const [tabIndexForPill, setTabIndexForPill] = useState(0);

  const tabs = [
    { id: 0, label: "Budget" },
    { id: 1, label: "Revenue" },
    { id: 2, label: "Expenditures" },
    { id: 3, label: "Salaries" },
    { id: 4, label: "Fund Balances" },
    { id: 5, label: "Documents" },
  ];

  function removeDistrict(value: string) {
    if (value) {
      return value.replace(/\bdistrict\b/i, "").trim();
    }
  }

  useEffect(() => {
    const handleEntitiesForSearch = async () => {
      const res: EntityFilter[] = await getCountyEntityList(selectedEntity?.id);
      const entityTypeInOrder = res.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setEntitiesForSearch(entityTypeInOrder);
      if (res?.length) {
        setSelectedEntity(entityTypeInOrder[0]);
        setIsLoadingNarrative(false);
        setEntityNarrative(entityTypeInOrder[0]?.entityNarrative || "");
      }
    };
    const handleLoad = async () => {
      const fiscalYear = await requestFiscalYearList();
      setFiscalYears(fiscalYear);
    };

    handleLoad();
    handleEntitiesForSearch();
  }, []);

  useEffect(() => {
    const getLocalDistrictSearchList = async () => {
      if (selectedEntity?.type !== undefined) {
        const res = await requestLocalDistrictSearch(selectedEntity?.type);
        const modifiedData = res.map((entity: any) => {
          return {
            entityID: entity.EntityID,
            entityName: entity.EntityName,
            Icon: handleTypeIcon(selectedEntity?.type),
            multipleCountiesFlag: entity.MultipleCountiesFlag,
            searchWords: entity.SearchWords,
            specificNarrative: entity.SpecificNarrative,
          };
        });
        setEntityNameList(modifiedData);
      }
    };
    getLocalDistrictSearchList();
  }, [selectedEntity]);

  const onTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const onBackTap = () => {
    if (selectedTab === 0) {
      setSelectedTab(5);
      return;
    }
    setSelectedTab(selectedTab - 1);
  };

  const onForwardTap = () => {
    if (selectedTab === 5) {
      setSelectedTab(0);
      return;
    }
    setSelectedTab(selectedTab + 1);
  };

  const onEntityChange = (item: any) => {
    setSelectedEntity(item);
    setIsLoadingNarrative(false);
    setEntityNarrative(item.entityNarrative);
    setSelectedEntityName({});
  };

  const onEntityNameChange = (item: any) => {
    setSelectedEntityName(item);
    setIsLoadingNarrative(false);
    setEntityNarrative(item.specificNarrative);
  };

  const setTabIndex = (index: number) => {
    setTabIndexForPill(index);
  };

  useEffect(() => {
    const handleEntitiesByCounty = async () => {
      setIsLoading(true);
      //eslint-disable-next-line
      const res: any = await getCountyEntitiesByEntityTypeID(
        selectedEntity?.type || ENTITY_TYPE_IDS.ABATEMENT_DISTRICT,
        selectedEntity?.id || 0,
        selectedFiscalYear
      );

      const filteredRes = res.map((entity: EntityFinancialFilter) => {
        if (entity.reportDocs) {
          entity.reportDocs = entity.reportDocs.filter((doc) => {
            return doc.Kind !== "Annual";
          });
        }
        return entity;
      });
      const dataInAlphabeticalOrder = filteredRes.sort(
        (a: EntityFinancialFilter, b: EntityFinancialFilter) =>
          a.name.localeCompare(b.name)
      );
      setCountyEntities(dataInAlphabeticalOrder);
      setShowDataByYear(false);
      setIsLoading(false);
    };
    handleEntitiesByCounty();
  }, [selectedEntity, selectedFiscalYear]);

  useEffect(() => {
    const handleLoad = async () => {
      setIsLoading(true);
      setShowDataByYear(
        Boolean(selectedEntityName && Object.keys(selectedEntityName).length)
      );

      if (Object.keys(selectedEntityName).length !== 0) {
        const fiscalYear = await requestFiscalYearList();
        setFiscalYears(fiscalYear);

        if (fiscalYear?.length > 0) {
          const results = await Promise.all(
            fiscalYear.map(async (year: any) => {
              const res = await getCountyEntitiesByEntityTypeID(
                selectedEntity?.type || ENTITY_TYPE_IDS.ABATEMENT_DISTRICT,
                selectedEntity?.id || 0,
                year
              );
              return res.map((item) => ({
                ...item,
                year,
              }));
            })
          );

          const filteredResults = results.flatMap((result) =>
            result.filter(
              (item: any) => item.name === selectedEntityName?.entityName
            )
          );

          const updatedResults = filteredResults.map((item) => ({
            ...item,
            year: item.year || null,
          }));

          setEntityDataByYear(updatedResults);
        }
      }
      setIsLoading(false);
    };

    handleLoad();
  }, [selectedEntityName]);

  return (
    <>
      <div className="ld-dropdown-container">
        <div tabIndex={0} aria-label="Search Entity Type dropdown">
          <h1 className="dropdown-header">Search Entity Type</h1>
          <div className="search-component-container">
            <SearchDropDownComponent
              entitiesData={entitiesForSearch}
              selectedItem={selectedEntity}
              setSelectedItem={onEntityChange}
              isEntitiesFiltered={true}
            />
          </div>
        </div>
        <div tabIndex={0} aria-label="Search Entity Name dropdown">
          <h1 className="dropdown-header">Search by county or entity name </h1>
          <div className="search-component-container">
            <LocalDistrictSearchDropDown
              entitiesData={entityNameList}
              selectedItem={selectedEntityName}
              setSelectedItem={onEntityNameChange}
              allIcon={true}
            />
          </div>
        </div>
      </div>
      <div className="narrative-tab-container">
        <div className="narrative-section">
          <div className="narrative-section__details" tabIndex={0}>
            {selectedEntityName?.entityName ? (
              <h2>{selectedEntityName?.entityName}</h2>
            ) : (
              <h2>{selectedEntity?.name ?? "Abatement District"}</h2>
            )}
            {isLoadingNarrative ? (
              <p className="narrative-section__details">Loading...</p>
            ) : (
              <p className="narrative-section__details">{entityNarrative}</p>
            )}
          </div>

          <div className="local-district-image-section">
            <img
              src={`${API_CONSTANTS.imageBucket}/Local+District+Photos/${
                selectedEntity?.name.replace(/ /g, "").replace(/\//g, "And") ??
                "AbatementDistrict"
              }Image${selectedEntity?.type ?? 6}.webp`}
              alt={`${selectedEntity?.name} Image`}
            />
          </div>
        </div>
      </div>
      <div className="report-section">
        <div className="report-section__tabsContainer">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              onClick={() => onTabChange(tab.id)}
              className={`report-section__tab ${
                selectedTab === tab.id ? "report-section__tab--selected" : ""
              }`}
              tabIndex={indexForTab}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  onTabChange(tab.id);
                  setIndexForTab(-1);
                }
                if (e.key === "Escape") {
                  const activeEl = document.activeElement as
                    | HTMLElement
                    | SVGElement;
                  if (activeEl?.blur) {
                    activeEl.blur();
                  }
                }
              }}
            >
              <h1>{tab.label}</h1>
            </div>
          ))}
        </div>
        <div
          className={
            selectedTab === 0 ? "tab-content-active" : "tab-content-inactive"
          }
          onFocus={() => setIndexForTab(0)}
        >
          <ReportSectionComponent
            onForwardTap={onForwardTap}
            onBackTap={onBackTap}
            reportContainerStyle="localDistrictReportContainer"
            reportTitleStyle="cityReportTitle"
            reportDescStyle="cityReportDesc"
            downloadTextContainerStyle="downloadTextContainer"
            title="Budget"
            pageName="local district"
            scrollable
            description="A budget is a financial plan that outlines how public funds will be allocated to services. It ensures that taxpayer dollars are spent in alignment with community priorities. Budgeting involves planning, reviewing, and approving to ensure financial resources are used efficiently and sustainably. A budget helps manage costs, address unexpected challenges, and maintain long-term financial health by tracking income and expenditures. Due to the varying size and complexity of local districts, their budgeting process may look significantly different than other similar entities."
            reportId={
              LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictBudget.reportId
            }
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-local-district-budget.png"
            slicerFilters={[
              {
                visualName:
                  LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictBudget
                    .visualNameEntityType,
                slicerState: {
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "District Budget",
                        column: "EntityType",
                      },
                      operator: "In",
                      values: [selectedEntity?.name ?? "Abatement District"],
                      filterType: models.FilterType.Basic,
                    },
                  ],
                },
              },
              {
                visualName:
                  LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictBudget
                    .visualNameEntityName,
                slicerState: {
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "District Budget",
                        column: "Entity Name Fix",
                      },
                      operator: "In",
                      values: [
                        selectedEntityName?.entityName &&
                          removeDistrict(selectedEntityName?.entityName),
                      ],
                      filterType: models.FilterType.Basic,
                    },
                  ],
                },
              },
            ]}
          />
        </div>
        <div
          className={
            selectedTab === 1 ? "tab-content-active" : "tab-content-inactive"
          }
          onFocus={() => setIndexForTab(0)}
        >
          <ReportSectionComponent
            onForwardTap={onForwardTap}
            onBackTap={onBackTap}
            reportContainerStyle="localDistrictReportContainer"
            reportTitleStyle="cityReportTitle"
            reportDescStyle="cityReportDesc"
            downloadTextContainerStyle="downloadTextContainer"
            title="Revenue by Category"
            pageName="local district"
            scrollable
            description="Revenue is the money collected by the government to fund public services and operations. This revenue primarily comes from taxes, fees, and other sources such as grants or fines. It ensures that the government can provide essential services to the communities they serve. Revenue is crucial for maintaining a balanced budget and supporting long-term financial stability. Due to the varying size and complexity of local districts, their revenue sources may look significantly different than other similar entities."
            reportId={
              LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictRevenue.reportId
            }
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-local-district-revenue.png"
            slicerFilters={[
              {
                visualName:
                  LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictRevenue
                    .visualNameEntityType,
                slicerState: {
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "District Budget",
                        column: "EntityType",
                      },
                      operator: "In",
                      values: [selectedEntity?.name ?? "Abatement District"],
                      filterType: models.FilterType.Basic,
                    },
                  ],
                },
              },
              {
                visualName:
                  LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictRevenue
                    .visualNameEntityName,
                slicerState: {
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "District Budget",
                        column: "Entity Name Fix",
                      },
                      operator: "In",
                      values: [
                        selectedEntityName?.entityName &&
                          removeDistrict(selectedEntityName?.entityName),
                      ],
                      filterType: models.FilterType.Basic,
                    },
                  ],
                },
              },
            ]}
          />
        </div>
        <div
          className={
            selectedTab === 2 ? "tab-content-active" : "tab-content-inactive"
          }
          onFocus={() => setIndexForTab(0)}
        >
          <ReportSectionComponent
            onForwardTap={onForwardTap}
            onBackTap={onBackTap}
            reportContainerStyle="localDistrictReportContainer"
            reportTitleStyle="cityReportTitle"
            reportDescStyle="cityReportDesc"
            downloadTextContainerStyle="downloadTextContainer"
            scrollable
            title="Expenditure by Category"
            pageName="local district"
            description="Expenditure is the money spent by the government to fund public services and programs. It represents the allocation of resources to meet the needs of the community and ensure the smooth operation of government functions. Expenditures are carefully planned and monitored to ensure that public funds are used efficiently and responsibly. Due to the varying size and complexity of local districts, their expenses may look significantly different than other similar entities."
            reportId={
              LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictExpenditure.reportId
            }
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-local-district-expenditure.png"
            slicerFilters={[
              {
                visualName:
                  LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictExpenditure
                    .visualNameEntityType,
                slicerState: {
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "District Budget",
                        column: "EntityType",
                      },
                      operator: "In",
                      values: [selectedEntity?.name ?? "Abatement District"],
                      filterType: models.FilterType.Basic,
                    },
                  ],
                },
              },
              {
                visualName:
                  LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictExpenditure
                    .visualNameEntityName,
                slicerState: {
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "District Budget",
                        column: "Entity Name Fix",
                      },
                      operator: "In",
                      values: [
                        selectedEntityName?.entityName &&
                          removeDistrict(selectedEntityName?.entityName),
                      ],
                      filterType: models.FilterType.Basic,
                    },
                  ],
                },
              },
            ]}
          />
        </div>
        <div
          className={
            selectedTab === 3 ? "tab-content-active" : "tab-content-inactive"
          }
          onFocus={() => setIndexForTab(0)}
        >
          <ReportSectionComponent
            onForwardTap={onForwardTap}
            onBackTap={onBackTap}
            reportContainerStyle="localDistrictReportContainer"
            reportTitleStyle="cityReportTitle"
            reportDescStyle="cityReportDesc"
            downloadTextContainerStyle="downloadTextContainer"
            scrollable
            title="Salaries"
            pageName="local district"
            description={
              selectedEntity?.type == 31 ? (
                <>
                  <strong>
                    Irrigation districts exempt from salary data reporting:
                  </strong>
                  &nbsp;As local government entities, Irrigation Districts are
                  required to submit financial information pursuant to section
                  67-1076. Idaho law, however, limits the scope of Irrigation
                  District information that is available to the general public.
                  Pursuant to sections 43-303 and 43-325, only electors (i.e.
                  patrons) of an Irrigation District and commissioners of
                  counties within the boundaries of the Irrigation District are
                  entitled to review broader information. Accordingly,
                  Irrigation Districts are exempt from the employee salary data
                  reporting requirements.
                </>
              ) : (
                <>
                  In this section, you can explore positions and salaries for
                  local district employees in the state of Idaho. This report
                  includes position and salary information for the local
                  district you have selected. Explore the interactive salary
                  data to learn more about what each local district expends to
                  pay its employees. The information provided includes the
                  salary amounts based on the most recent report.
                </>
              )
            }
            reportId={
              LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictSalaries.reportId
            }
            comingSoon={true}
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-city-salaries.png"
            comingSoonImageUrl="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-salary-coming-soon.webp"
            slicerFilters={[
              {
                visualName:
                  LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictSalaries
                    .visualNameEntityType,
                slicerState: {
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "District_Employee_Salary",
                        column: "EntityType",
                      },
                      operator: "In",
                      values: [selectedEntity?.name ?? "Abatement District"],
                      filterType: models.FilterType.Basic,
                    },
                  ],
                },
              },
              {
                visualName:
                  LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictSalaries
                    .visualNameEntityName,
                slicerState: {
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "District_Employee_Salary",
                        column: "Entity Name Fixed",
                      },
                      operator: "In",
                      values: [
                        selectedEntityName?.entityName &&
                          removeDistrict(selectedEntityName?.entityName),
                      ],
                      filterType: models.FilterType.Basic,
                    },
                  ],
                },
              },
            ]}
          />
        </div>
        <div
          className={
            selectedTab === 4 ? "tab-content-active" : "tab-content-inactive"
          }
          onFocus={() => setIndexForTab(0)}
        >
          <ReportSectionComponent
            onForwardTap={onForwardTap}
            onBackTap={onBackTap}
            reportContainerStyle="localDistrictReportContainer"
            reportTitleStyle="cityReportTitle"
            reportDescStyle="cityReportDesc"
            downloadTextContainerStyle="downloadTextContainer"
            scrollable
            title="Fund Balances by Classification"
            pageName="local district"
            description={
              "After providing the budgeted services for each year, counties commonly have year-end funds /remaining cash set aside for several reasons. The most common purpose is to cover expenses for the first quarter of the subsequent year. These balances act as a financial safety net, allowing the entity to handle unexpected costs and invest in long-term initiatives, ensuring stability and progress for the community. Fund balances are classified as Assigned, Committed, Non-spendable, Restricted, and Unassigned; these classifications direct how the money will be used."
            }
            reportId={
              LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictFundBalance.reportId
            }
            powerBiPlaceholderImg="https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/powerbi-placeholders/placeholder-local-district-fund-balance.png"
            slicerFilters={[
              {
                visualName:
                  LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictFundBalance
                    .visualNameEntityType,
                slicerState: {
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "rep District_Fund_Balances",
                        column: "EntityType",
                      },
                      operator: "In",
                      values: [selectedEntity?.name ?? "Abatement District"],
                      filterType: models.FilterType.Basic,
                    },
                  ],
                },
              },
              {
                visualName:
                  LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictFundBalance
                    .visualNameEntityName,
                slicerState: {
                  filters: [
                    {
                      $schema: "http://powerbi.com/product/schema#basic",
                      target: {
                        table: "rep District_Fund_Balances",
                        column: "Entity Name Fix",
                      },
                      operator: "In",
                      values: [
                        selectedEntityName?.entityName &&
                          removeDistrict(selectedEntityName?.entityName),
                      ],
                      filterType: models.FilterType.Basic,
                    },
                  ],
                },
              },
            ]}
          />
        </div>
        <div
          className={
            selectedTab === 5 ? "tab-content-active" : "tab-content-inactive"
          }
          onFocus={() => setIndexForTab(0)}
        >
          <div className="report-section__detailContainer">
            <div className="reportMobileNav">
              <div
                onClick={onBackTap}
                className="reportMobileNav__iconContainer"
              >
                <CheveronLeft />
              </div>
              <h5 className="reportMobileNav__text">Documents</h5>
              <div
                onClick={onForwardTap}
                className="reportMobileNav__iconContainer"
              >
                <CheveronRight />
              </div>
            </div>
            <div className="report-section__data">
              <h2 className="report-section__heading">Documents</h2>
              <p className="report-section__details">
                Below you will find defined fiscal year, the actuals for the
                previous fiscal years, and an audit if applicable.
              </p>
              {!showDataByYear && (
                <div className="pill-container">
                  {fiscalYears?.map((year) => (
                    <div
                      tabIndex={tabIndexForPill}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          const targetElement = e.target as HTMLElement;
                          const yearText =
                            targetElement.querySelector(
                              ".tag-pill"
                            )?.textContent;

                          if (yearText) {
                            const year = yearText.replace("YEAR ", "");
                            setSelectedFiscalYear(year);
                          }
                          setTabIndexForPill(-1);
                        }

                        if (e.key === "Escape") {
                          const activeEl = document.activeElement as
                            | HTMLElement
                            | SVGElement;
                          if (activeEl?.blur) {
                            activeEl.blur();
                          }
                        }
                      }}
                      onBlur={() => setTabIndexForPill(0)}
                    >
                      <YearTagPill
                        key={year}
                        year={year}
                        selectedYear={selectedFiscalYear}
                        setSelectedYear={setSelectedFiscalYear}
                      />
                    </div>
                  ))}
                </div>
              )}
              {!showDataByYear && (
                <div className="filter-table">
                  {countyEntities?.length > 0 ? (
                    countyEntities?.map((data: EntityFinancialFilter) => (
                      <EntityListCard
                        key={data.id}
                        isLoading={isLoading}
                        Icon={handleTypeIcon(selectedEntity?.type)}
                        entityName={data.name}
                        actualExpenditures={data.expenseActual}
                        actualRevenue={data.revenueActual}
                        budgetedExpenditures={data.expenseBudget}
                        budgetedRevenue={data.revenueBudget}
                        reportDocs={data.reportDocs}
                      />
                    ))
                  ) : (
                    <EntityListCard
                      noData
                      entityName=""
                      isLoading={isLoading}
                      actualExpenditures={""}
                      actualRevenue={""}
                      budgetedExpenditures={""}
                      budgetedRevenue={""}
                    />
                  )}
                </div>
              )}
              {showDataByYear && (
                <div className="filter-table">
                  {entityDataByYear?.length > 0 ? (
                    entityDataByYear?.map((data: any) => (
                      <EntityListCard
                        key={data.year}
                        isLoading={isLoading}
                        Icon={handleTypeIcon(selectedEntity?.type)}
                        entityName={data.year}
                        actualExpenditures={data.expenseActual}
                        actualRevenue={data.revenueActual}
                        budgetedExpenditures={data.expenseBudget}
                        budgetedRevenue={data.revenueBudget}
                        reportDocs={data.reportDocs}
                      />
                    ))
                  ) : (
                    <EntityListCard
                      noData
                      entityName=""
                      isLoading={isLoading}
                      actualExpenditures={""}
                      actualRevenue={""}
                      budgetedExpenditures={""}
                      budgetedRevenue={""}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalDistrictSearchComponent;
