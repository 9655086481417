import { FC, useEffect, useState } from "react";
import { FileArrowDown } from "styled-icons/fa-solid";
import {
  EntityFinancialFilter,
  ReportDoc,
} from "../../types/interfaces/entityInterfaces";
import CityIcon from "../../styles/icons/CityIcon";
import "./entityListCard.scss";
import { getCitiesFinancial } from "../../pages/cityLandingPage/cityLandingPage.service";

type entityListCardProps = {
  year: string;
  cityId?: number;
};

const EntityListCardYear: FC<entityListCardProps> = (props) => {
  const [selectedItem, setSelectedItem] = useState<EntityFinancialFilter>();
  const [isLoading, setIsLoading] = useState(true);
  const [searchListData, setSearchListData] = useState<EntityFinancialFilter[]>(
    []
  );

  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    const getCitiesCall = async () => {
      setSearchListData([]);
      setIsLoading(true);
      try {
        const res: EntityFinancialFilter[] = await getCitiesFinancial(
          props.year
        );
        setSearchListData(res);
        handleSelectedItem(res);
      } finally {
        setIsLoading(false);
      }
    };
    getCitiesCall();
  }, []);

  useEffect(() => {
    handleSelectedItem(searchListData);
  }, [props.cityId]);

  const handleDisplayReportName = (report: ReportDoc) => {
    if (report.Version && Number(report.Version) > 2) {
      return `Amended ${report.Kind} ${Number(report.Version) - 1}`;
    } else if (report.Version) {
      return `Amended ${report.Kind}`;
    } else {
      return report.Kind;
    }
  };

  const handleSelectedItem = (cities: EntityFinancialFilter[]) => {
    if (!props.cityId || !cities.some((city) => city.id === props.cityId)) {
      setSelectedItem(cities[0]);
    } else {
      setSelectedItem(cities.find((city) => city.id === props.cityId));
    }
  };

  const LoadingDataCard = () => (
    <div className="entity-card-container no-entity">
      <p className="data-not-found body-large">LOADING DATA...</p>
    </div>
  );

  const NoDataCard = () => (
    <div className="entity-card-container no-entity">
      <p className="data-not-found body-large">NO DATA REPORTED</p>
    </div>
  );

  return (
    <>
      {isLoading && <LoadingDataCard />}
      {!isLoading && !selectedItem && <NoDataCard />}
      {!isLoading && selectedItem && (
        <div className="entity-card-container">
          <div className="entity-icon-name-container">
            <CityIcon />
            <p className="entity-name">{props.year}</p>
          </div>
          {selectedItem?.expenseActual ||
          selectedItem?.revenueActual ||
          selectedItem?.expenseBudget ? (
            <div className="data-type-info-container">
              <div>
                {selectedItem?.expenseActual !== null && (
                  <p className="data-type body-large">Actual</p>
                )}
                {selectedItem?.expenseBudget !== null && (
                  <p className="data-type body-large">Budget</p>
                )}
              </div>
              <div>
                <p className="data-info-values body-large">Revenues</p>
                {selectedItem?.revenueActual !== null && (
                  <p className="data-info-values body-large">
                    {USDollar.format(Number(selectedItem?.revenueActual))}
                  </p>
                )}
                {selectedItem?.expenseBudget !== null && (
                  <p className="data-info-values body-large">
                    {USDollar.format(Number(selectedItem?.expenseBudget))}
                  </p>
                )}
              </div>
              <div>
                <p className="data-info-values body-large">Expenditures</p>
                {selectedItem?.expenseActual !== null && (
                  <p className="data-info-values body-large">
                    {USDollar.format(Number(selectedItem?.expenseActual))}
                  </p>
                )}
                {selectedItem?.expenseBudget !== null && (
                  <p className="data-info-values body-large">
                    {USDollar.format(Number(selectedItem?.expenseBudget))}
                  </p>
                )}
              </div>

              {selectedItem?.type ? (
                <span className="download-link-container">
                  {selectedItem?.reportDocs &&
                    selectedItem?.reportDocs.map((report) => (
                      <div
                        key={report.ReportName + report.Version ?? ""}
                        className="download-link-container__row"
                      >
                        <FileArrowDown color="#3E5E94" size={24} />
                        <a
                          href={report.Url}
                          target="_blank"
                          aria-label={`Link to download ${report.ReportName}`}
                          className="download-link body-large"
                        >
                          {handleDisplayReportName(report)}
                        </a>
                      </div>
                    ))}
                </span>
              ) : (
                <span className="no-budget-space" />
              )}
            </div>
          ) : (
            <div className="not-found-container">
              <p className="data-not-found body-large">
                DATA NOT REPORTED BY ENTITY
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EntityListCardYear;
