import { FC, memo } from "react";

const SewerDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9193 15.082H20.8359V17.1654H14.5859V14.0404H16.6693V11.957H14.5859V6.7487C14.5859 6.19616 14.3664 5.66626 13.9757 5.27556C13.585 4.88486 13.0551 4.66536 12.5026 4.66536H4.16927V2.58203H2.08594V10.9154H4.16927V8.83203H10.4193V11.957H8.33594V14.0404H10.4193V19.2487C10.4193 19.8012 10.6388 20.3311 11.0295 20.7218C11.4202 21.1125 11.9501 21.332 12.5026 21.332H20.8359V23.4154H22.9193"
        fill="black"
      />
    </svg>
  );
});

export default SewerDistrictIcon;
