import { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { ArrowIosBack } from "styled-icons/evaicons-solid";
import {
  HOME_PAGE_CONSTANTS,
  URL_CONSTANTS,
} from "../../../../types/constants";
import "./hamburgerMenu.scss";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const handleClassName = (thisPath: string) => {
    return currentPath === thisPath ? "active-link link" : "inactive-link link";
  };

  return (
    <>
      <button
        className="hamburger-menu"
        onClick={() => setIsOpen(!isOpen)}
        aria-controls="primary-navigation"
        aria-expanded={isOpen ? "true" : "false"}
        aria-label="Hambuger Menu Button"
      >
        <svg className="hamburger" viewBox="0 0 100 100" width={38} height={38}>
          <rect
            className="line top"
            width={80}
            height={10}
            x={10}
            y={20}
            rx={5}
          />
          <rect
            className="line middle"
            width={80}
            height={10}
            x={10}
            y={45}
            rx={5}
          />
          <rect
            className="line bottom"
            width={80}
            height={10}
            x={10}
            y={70}
            rx={5}
          />
        </svg>
      </button>
      <div
        className={"hamburger-menu__dropdown"}
        aria-expanded={isOpen ? "true" : "false"}
      >
        <Link
          className={handleClassName(URL_CONSTANTS.HOME)}
          to={URL_CONSTANTS.HOME}
        >
          {HOME_PAGE_CONSTANTS.headerLabels.HOME}
        </Link>
        <Link
          className={handleClassName(URL_CONSTANTS.WORKFORCE_SALARY)}
          to={URL_CONSTANTS.WORKFORCE_SALARY}
        >
          {HOME_PAGE_CONSTANTS.headerLabels.WORKFORCE_SALARY}
        </Link>
        <Link
          className={handleClassName(URL_CONSTANTS.STATE)}
          to={URL_CONSTANTS.STATE}
        >
          {HOME_PAGE_CONSTANTS.headerLabels.STATE}
        </Link>
        <Link
          className={handleClassName(URL_CONSTANTS.COUNTY)}
          to={URL_CONSTANTS.COUNTY}
        >
          {HOME_PAGE_CONSTANTS.headerLabels.COUNTY}
        </Link>
        <Link
          className={handleClassName(URL_CONSTANTS.CITY)}
          to={URL_CONSTANTS.CITY}
        >
          {HOME_PAGE_CONSTANTS.headerLabels.CITY}
        </Link>
        <Link
          className={handleClassName(URL_CONSTANTS.LOCAL_DISTRICT)}
          to={URL_CONSTANTS.LOCAL_DISTRICT}
        >
          {HOME_PAGE_CONSTANTS.headerLabels.LOCAL_DISTRICT}
        </Link>
        <Link
          className={handleClassName(URL_CONSTANTS.EDUCATION)}
          to={URL_CONSTANTS.EDUCATION}
        >
          {HOME_PAGE_CONSTANTS.headerLabels.EDUCATION}
        </Link>
      </div>
    </>
  );
};
export default HamburgerMenu;
