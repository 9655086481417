export const HOME_PAGE_CONSTANTS = {
  cardSection: {
    title: {
      STATE_DATA: "State Data",
      WORKFORCE_SALARY: "Workforce & Salary",
      EDUCATION_DATA: "Education Data",
      COUNTY_DATA: "County Data",
      CITY_DATA: "City Data",
      LOCAL_DISTRICT_DATA: "Local District Data",
    },
    subtitle: {
      STATE_DATA: "BUDGETS / REVENUES / EXPENDITURES BY STATE AGENCIES",
      WORKFORCE_SALARY: "SALARIES / WORKFORCE",
      EDUCATION: "BUDGETS / REVENUES / EXPENDITURES BY EDUCATION PROVIDER",
      COUNTY: "BUDGETS / REVENUES / EXPENDITURES BY COUNTY",
      CITY: "BUDGETS / REVENUES / EXPENDITURES BY CITY",
      LOCAL_DISTRICTS: "BUDGETS / REVENUES / EXPENDITURES BY LOCAL DISTRICT",
    },
  },
  buttons: {
    EXPLORE_MORE: "Explore More",
    EXPLORE_DATA: "Explore Data",
    READ_MORE: " Read More",
  },
  EXPLORE_THE_DATA: "EXPLORE THE DATA",
  headerLabels: {
    HOME: "Home",
    WORKFORCE_SALARY: "Workforce & Salary",
    STATE: "State",
    COUNTY: "County",
    CITY: "City",
    EDUCATION: "Education",
    LOCAL_DISTRICT: "Local District",
  },
  homePageHero: {
    WELCOME_TO: "Welcome To",
    TRANSPARENT_IDAHO: "Transparent Idaho",
    SUB_TEXT:
      "Enhancing transparency in government by making financial and operational data visible and accessible to all Idahoans.",
  },
  carouselOne: {
    QUOTE:
      "“Openness and collaboration between governmental leaders and citizens are essential for Idaho’s future”",
    BRANDON_CONTROLLER: "Brandon D Woolf | STATE CONTROLLER OF IDAHO",
    FIND_OUT_MORE_BUTTON:
      "Find out more about Brandon and his initiatives by visiting the State Controller’s website.",
    VISIT_WEBSITE_BUTTON: "Visit Site",
  },
  carouselTwo: {
    HIGHEST_SALARIES: "Highest Salaries",
    SEE_HOW: "See how employee salaries match up across the State of Idaho.",
    LEARN_MORE: "Learn More",
  },
  carouselThree: {
    TOWNHALL_IDAHO: "Townhall Idaho",
    INFO: "Townhall Idaho encourages citizens to engage with their government by facilitating easy access to public meeting information for state executive agencies.",
    VIEW: "View Public Meeting Notices",
  },
  carouselFour: {
    UNIFORM_ACCOUNTING: "Uniform Accounting and Reporting Manual",
    INFO: "The Uniform Accounting Manual standardizes financial reporting practices for Idaho's local governmental entities, offering clear guidelines that ensure financial transparency for citizens and decision-makers. Please note the interim status of this report; the finalized version will be available by January 1, 2025, to meet legislative requirements.",
    MOBILEINFO:
      "The Uniform Accounting Manual standardizes Idaho local government reporting. The interim report will be finalized by January 1, 2025 to meet legislative requirements.",
    VIEW: "View Manual",
  },
  BROUGHT_BY: "Brought to you by Brandon D Woolf",
  PHONE: "(208) 334 - 3100",
  QUICKLINKS_DESC: "Conveniently navigate to frequently visited pages.",
  BANNER_TEXT:
    "Our API’s are down. The expected outage will be 12 hours. Please check back at approximately 4:30pm MST as the data will be functioning then.",
};
