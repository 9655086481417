import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeftCircle,
  ChevronRightCircle,
} from "styled-icons/boxicons-solid";
import {
  API_CONSTANTS,
  HOME_PAGE_CONSTANTS,
  NAV_TAGS,
  URL_CONSTANTS,
} from "../../../../types/constants";
import { GradientButton } from "../../../../components";
import "./homePageCarousel.scss";

type Props = {
  cardSelected: number;
  setCardSelected: Dispatch<SetStateAction<number>>;
};

const HomePageCarousel = ({ cardSelected, setCardSelected }: Props) => {
  const ref = useRef<HTMLOListElement>(null);
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  const [isCarouselHovered, setIsCarouselHovered] = useState<boolean>(false);
  const scroll = (scrollOffset: number) => {
    ref.current && (ref.current.scrollLeft += scrollOffset);
  };
  const navigate = useNavigate();
  useEffect(() => {
    // Hack-y way of fixing sizing problems when resizing the window
    window.addEventListener("resize", () => {
      if (window.innerWidth !== windowSize) {
        setWindowSize(window.innerWidth);
        scroll(-1000000);
        setCardSelected(1);
      }
    });
    return () => {
      window.removeEventListener("resize", () =>
        setWindowSize(window.innerWidth)
      );
    };
  }, [windowSize]);

  const handleSelectSlide = () => {
    if (cardSelected === 1) {
      setCardSelected(2);
      scroll(windowSize);
    } else if (cardSelected === 2) {
      setCardSelected(3);
      scroll(windowSize);
    } else if (cardSelected === 3) {
      setCardSelected(4);
      scroll(windowSize);
    } else if (cardSelected === 4) {
      setCardSelected(1);
      scroll(-(windowSize * 3));
    }
  };

  useEffect(() => {
    if (!isCarouselHovered) {
      const timeout = setTimeout(handleSelectSlide, 9000);
      return () => clearInterval(timeout);
    }
  }, [cardSelected, isCarouselHovered]);

  return (
    <div className="carousel-container">
      <div
        className="carousel-body"
        onMouseOver={() => setIsCarouselHovered(true)}
        onMouseLeave={() => setIsCarouselHovered(false)}
        tabIndex={0}
        aria-label="Homepage carousel"
        onFocus={() => setIsCarouselHovered(true)}
        onBlur={() => setIsCarouselHovered(false)}
      >
        <section className="carousel" aria-label="Gallery">
          <ol className="carousel__viewport" ref={ref}>
            <li className="carousel__slide carousel-one-background">
              <div>
                <a
                  className="carousel__prev chevron-left"
                  role="button"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      scroll(windowSize * 3);
                      setCardSelected(4);
                    }
                  }}
                >
                  <ChevronLeftCircle
                    onClick={() => {
                      scroll(windowSize * 3);
                      setCardSelected(4);
                    }}
                    size={35}
                  />
                </a>
                <div className="brandon-carousel" tabIndex={0}>
                  <h3 className="brandon-quote">
                    {HOME_PAGE_CONSTANTS.carouselOne.QUOTE}
                  </h3>
                  <p className="brandon-controller">
                    {"• "}
                    {HOME_PAGE_CONSTANTS.carouselOne.BRANDON_CONTROLLER}
                  </p>
                  <p className="button-caption">
                    {HOME_PAGE_CONSTANTS.carouselOne.FIND_OUT_MORE_BUTTON}
                  </p>
                  <div className="carousel-button-container">
                    <GradientButton
                      buttonSize="button-text-small"
                      buttonText={
                        HOME_PAGE_CONSTANTS.carouselOne.VISIT_WEBSITE_BUTTON
                      }
                      ButtonAction={() =>
                        window.open(
                          "https://www.sco.idaho.gov/LivePages/scohome.aspx"
                        )
                      }
                    />
                  </div>
                </div>
                <a
                  className="carousel__next chevron-right"
                  role="button"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      scroll(windowSize);
                      setCardSelected(2);
                    }
                  }}
                >
                  <ChevronRightCircle
                    onClick={() => {
                      scroll(windowSize);
                      setCardSelected(2);
                    }}
                    size={35}
                  />
                </a>
              </div>
            </li>
            <li className="carousel__slide flex-right carousel-two-background">
              <div className="carousel__snapper"></div>
              <a
                className="carousel__prev chevron-left"
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    scroll(-windowSize);
                    setCardSelected(1);
                  }
                }}
              >
                <ChevronLeftCircle
                  size={35}
                  onClick={() => {
                    scroll(-windowSize);
                    setCardSelected(1);
                  }}
                />
              </a>
              <div className="flex-carousel" tabIndex={0}>
                <h3>{HOME_PAGE_CONSTANTS.carouselTwo.HIGHEST_SALARIES}</h3>

                <p>{HOME_PAGE_CONSTANTS.carouselTwo.SEE_HOW}</p>
                <div className="carousel-button-container">
                  <GradientButton
                    buttonSize="button-text-small"
                    buttonText={HOME_PAGE_CONSTANTS.carouselTwo.LEARN_MORE}
                    ButtonAction={() =>
                      navigate(URL_CONSTANTS.WORKFORCE_SALARY, {
                        state: {
                          tag: NAV_TAGS.EMPLOYEE_HIGHEST_PAID,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <a
                className="carousel__next chevron-right"
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    scroll(windowSize);
                    setCardSelected(3);
                  }
                }}
              >
                <ChevronRightCircle
                  size={35}
                  onClick={() => {
                    scroll(windowSize);
                    setCardSelected(3);
                  }}
                />
              </a>
            </li>
            <li className="carousel__slide flex-left carousel-three-background">
              <div className="carousel__snapper"></div>
              <a
                className="carousel__prev chevron-left"
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    scroll(-windowSize);
                    setCardSelected(2);
                  }
                }}
              >
                <ChevronLeftCircle
                  size={35}
                  onClick={() => {
                    scroll(-windowSize);
                    setCardSelected(2);
                  }}
                />
              </a>
              <div className="flex-carousel" tabIndex={0}>
                <h3>{HOME_PAGE_CONSTANTS.carouselThree.TOWNHALL_IDAHO}</h3>

                <p>{HOME_PAGE_CONSTANTS.carouselThree.INFO}</p>
                <div className="carousel-button-container">
                  <GradientButton
                    buttonSize="button-text-small"
                    buttonText={HOME_PAGE_CONSTANTS.carouselThree.VIEW}
                    ButtonAction={() =>
                      window.open("https://townhall.idaho.gov/")
                    }
                  />
                </div>
              </div>
              <a
                className="carousel__next chevron-right"
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    scroll(windowSize);
                    setCardSelected(4);
                  }
                }}
              >
                <ChevronRightCircle
                  size={35}
                  onClick={() => {
                    scroll(windowSize);
                    setCardSelected(4);
                  }}
                />
              </a>
            </li>

            <li className="carousel__slide flex-left carousel-four-background">
              <div className="carousel__snapper"></div>
              <a
                className="carousel__prev chevron-left"
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    scroll(-windowSize);
                    setCardSelected(3);
                  }
                }}
              >
                <ChevronLeftCircle
                  size={35}
                  onClick={() => {
                    scroll(-windowSize);
                    setCardSelected(3);
                  }}
                />
              </a>
              <div className="flex-carousel forth-carousel" tabIndex={0}>
                <h3>{HOME_PAGE_CONSTANTS.carouselFour.UNIFORM_ACCOUNTING}</h3>

                <p className="p-default">
                  {HOME_PAGE_CONSTANTS.carouselFour.INFO}
                </p>
                <p className="p-mobile">
                  {HOME_PAGE_CONSTANTS.carouselFour.MOBILEINFO}
                </p>
                <div className="carousel-button-container">
                  <GradientButton
                    buttonSize="button-text-small"
                    buttonText={HOME_PAGE_CONSTANTS.carouselFour.VIEW}
                    ButtonAction={() =>
                      window.open(
                        `${API_CONSTANTS.imageBucket}/pdfs/Interim%20UAM_Final%20PDF%20Version.pdf`
                      )
                    }
                  />
                </div>
              </div>
              <a
                className="carousel__next chevron-right"
                role="button"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    scroll(-(windowSize * 3));
                    setCardSelected(1);
                  }
                }}
              >
                <ChevronRightCircle
                  size={35}
                  onClick={() => {
                    scroll(-(windowSize * 3));
                    setCardSelected(1);
                  }}
                />
              </a>
            </li>
          </ol>
        </section>
        <div className="circles-container">
          <div
            className={cardSelected === 1 ? "circle-selected circle" : "circle"}
          />
          <div
            className={cardSelected === 2 ? "circle-selected circle" : "circle"}
          />
          <div
            className={cardSelected === 3 ? "circle-selected circle" : "circle"}
          />
          <div
            className={cardSelected === 4 ? "circle-selected circle" : "circle"}
          />
        </div>
      </div>
    </div>
  );
};

export default HomePageCarousel;
