import { FC, memo } from "react";

const SoilWaterConservationIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.25573 3.88676C4.06647 3.88483 3.88294 3.95167 3.73925 4.07486C3.59556 4.19805 3.50147 4.36922 3.47448 4.55655C3.41419 4.98448 3.38356 5.41607 3.38281 5.84822C3.38281 10.5857 7.05365 14.4628 11.7161 14.8555V21.3347C11.7161 21.5419 11.7985 21.7406 11.945 21.8871C12.0915 22.0336 12.2902 22.1159 12.4974 22.1159C12.7046 22.1159 12.9033 22.0336 13.0498 21.8871C13.1963 21.7406 13.2786 21.5419 13.2786 21.3347V14.8555C17.9411 14.4628 21.612 10.5847 21.612 5.84718C21.612 5.40968 21.5811 4.97912 21.5193 4.55551C21.492 4.36836 21.3979 4.19745 21.2542 4.07446C21.1105 3.95148 20.9271 3.88479 20.738 3.88676C18.9948 3.90146 17.2923 4.41521 15.8319 5.3672C14.3715 6.3192 13.2143 7.6696 12.4974 9.25864C11.7804 7.66944 10.6231 6.31893 9.16248 5.36692C7.70189 4.41491 5.99913 3.90126 4.25573 3.88676Z"
          fill="black"
        />
      </svg>
    );
  }
);

export default SoilWaterConservationIcon;
