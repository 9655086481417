export const ABOUT_SECTION_CONSTANTS = {
  EDUCATION: {
    TITLE: "About Education Data",
    DESCRIPTION: `Idaho’s public schools are primarily funded through state general funds, supplemented by state dedicated funds, federal funds, and local tax revenues. State general and dedicated funds are distributed to public schools according to state statutes, ensuring compliance with established funding guidelines.

      The term Local Education Authorities (LEAs) refers to a broad group of education providers, including virtual/online schools, charter schools, and traditional school districts.

      To enhance financial transparency, the Local Transparency Team, in collaboration with the Office of the Idaho State Board of Education, the Idaho State Department of Education, and the Idaho System for Educational Excellence, has compiled year-over-year financial, transactional, salary, and enrollment data for all Idaho LEAs. This effort provides a picture of how resources are allocated and utilized across the state’s education system.`,
    DATA_INFO:
      "Data provided by the Office of the State Board of Education and the Idaho State Department of Education over multiple fiscal years.",
    IMG_ALT: "Kids studying in class",
  },
  LOCAL_DISTRICTS: {
    TITLE: "About Local Districts",
    DESCRIPTION: `Local Districts are entities of local government that deliver specialized services within specific geographic areas, ranging from ambulance and fire protection to mosquito abatement and other essential functions. These districts can be classified as either taxing or non-taxing. Taxing districts have the authority to levy taxes to fund their services, whereas non-taxing districts operate without that ability.

      To promote transparency and accountability, the Local Transparency Project Team, housed in the Idaho State Controller’s Office, worked closely with Local District leaders to establish a standardized template for reporting financial data. This includes budgeted revenue and expenditure information, fund balances, and salary data, providing citizens with consistent and clear insights into district operations.

      <strong>Urban Renewal Agency Annual Report</strong>
      <a href="#urbanRenewalRef">Urban Renewal Agencies</a>, authorized under Idaho law, are another critical component of local government. These agencies are tasked with addressing community and economic challenges, including eliminating unsafe or deteriorating conditions, fostering economic development, and preventing further decline in designated areas. Idaho State Code 50-2006(5)(c) requires Urban Renewal Agencies to prepare and submit an annual report to their local governing body. They must also approve and adopt an annual budget, which is filed for informational purposes.

      The annual reports from Urban Renewal Agencies provide a detailed view of their activities, plans, and financial management, offering valuable insights into their efforts to enhance Idaho’s communities. These reports can be accessed below to better understand how Urban Renewal Agencies are working to create safer, more vibrant areas for residents and businesses.`,
    DATA_INFO:
      "Data collected from Local Districts over multiple fiscal years.",
    IMG_ALT: "A pin being placed on a map",
  },
  STATE: {
    TITLE: "About State Data",
    DESCRIPTION:
      "Transparent Idaho provides an in-depth look at the inner workings of state government by allowing citizens to delve into financial and workforce data from each state agency. Citizens can see the statewide financial perspective or dive into agency-specific data with ease.",
    DATA_INFO:
      "Data provided by the Office of the State Board of Education and the Idaho State Department of Education over multiple fiscal years..",
    IMG_ALT: "Kids studying in class",
  },
  WORKFORCE_SALARY: {
    TITLE: "About State Workforce",
    DESCRIPTION:
      "The State of Idaho is the largest employer in the state, providing employment to over 35,000 individuals. Within the state workforce reports below you will find important workforce and salary data for all state employees including pay rates, employee counts, tenure, and trends.",
    DATA_INFO: "",
    IMG_ALT: "Idaho's workforce at work",
  },
  CITY: {
    TITLE: "About City Data",
    DESCRIPTION: `The Local Transparency Project Team, housed in the State Controller’s Office, worked with city leadership and the Association of Idaho Cities to establish a template to report uniform budgeted revenue and expenditure information.

      Transparent Idaho provides city revenue and expenditure data budgeted yearly and approved by city leadership. This data includes a checkbook-level analysis of incoming and outgoing funds. Due to the uniformity created throughout this effort, Idahoans now have the opportunity to analyze and compare various cities’ budgets as well as revenue and expenditure data.

`,
    DATA_INFO: "Data is collected from Cities over multiple fiscal years.",
    IMG_ALT: "A view of downtown boise at night",
  },
  COUNTIES: {
    TITLE: "About County Data",
    DESCRIPTION: `The Local Transparency Project Team, housed in the State Controller’s Office, worked with county leadership and the Idaho Association of Counties to establish a template to report uniform budgeted revenue and expenditure information.
    
 Transparent Idaho provides county revenue and expenditure data budgeted yearly and approved by county leadership. This data includes a checkbook-level analysis of incoming and outgoing funds. Due to the uniformity created throughout this effort, Idahoans now have the opportunity to analyze and compare various counties’ budgets as well as revenue and expenditure data.`,
    DATA_INFO: "Data collected from Counties over multiple fiscal years.",
    IMG_ALT: "State capital building",
  },

  VENDOR_PAYMENTS: {
    TITLE: "About Vendor Payments",
    DESCRIPTION: `The State of Idaho partners with various vendors to provide goods and services to state agencies as they serve the citizens of Idaho. The vendor payment data spans from the most utilized vendors to detailed transaction-level data. The comprehensive list captures every dollar spent, providing you with a detailed and insightful view of Idaho's financial transactions.`,
    DATA_INFO: "",
    IMG_ALT: "The state capital building",
  },
};
