import {
  Dispatch,
  SetStateAction,
  useState,
  FC,
  useRef,
  useEffect,
} from "react";
import { ChevronDown, ChevronUp } from "styled-icons/boxicons-solid";
import { Close } from "styled-icons/material-rounded";
import "./searchComponent.scss";
import { PestAbatementIcon, TypeCountyIcon } from "../../styles/icons";

interface Entity {
  entityName: string;
  entityID: number;
  searchWords: string;
  Icon?: FC<React.SVGProps<SVGSVGElement>>;
  multipleCountiesFlag: string;
  specificNarrative: string;
}

interface searchDropDownComponentProps {
  selectedItem: Entity | undefined;
  setSelectedItem: Dispatch<SetStateAction<Entity | undefined>>;
  entitiesData?: Entity[];
  DefaultIcon?: FC<React.SVGProps<SVGSVGElement>>;
  allIcon?: boolean;
  CustomIcon?: FC<React.SVGProps<SVGSVGElement>>;
}

const LocalDistrictSearchDropDown: FC<searchDropDownComponentProps> = ({
  selectedItem,
  setSelectedItem,
  entitiesData,
  DefaultIcon,
  allIcon,
  CustomIcon,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterText, setFilterText] = useState<string>("");
  const catMenuRef = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (
        catMenuRef.current &&
        !catMenuRef.current.contains(e.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const handleFilterSearch = (): Entity[] => {
    if (Array.isArray(entitiesData)) {
      return entitiesData.filter((item) =>
        item.searchWords.toLowerCase().includes(filterText.toLowerCase())
      );
    }
    return [];
  };

  const handleClick = (item: Entity) => {
    setSelectedItem(item);
    setDropdownOpen(false);
    setFilterText("");
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number,
    totalOptions: number,
    item: Entity
  ) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleClick(item);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      focusNextOption(index, totalOptions);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      focusPreviousOption(index, totalOptions);
    }
  };

  const focusNextOption = (currentIndex: number, totalOptions: number) => {
    const nextIndex = (currentIndex + 1) % totalOptions;
    optionRefs.current[nextIndex]?.focus();
  };

  const focusPreviousOption = (currentIndex: number, totalOptions: number) => {
    const previousIndex = (currentIndex - 1 + totalOptions) % totalOptions;
    optionRefs.current[previousIndex]?.focus();
  };

  return (
    <div className="local-district-filter-container" ref={catMenuRef}>
      <div onClick={toggleDropdown} className="ld-selected-selected-container">
        <div className="selected-icon-text">
          {DefaultIcon && <DefaultIcon />}
          {allIcon && <TypeCountyIcon />}
          {CustomIcon && <CustomIcon />}
          {selectedItem?.entityName || "Type county or specific local district"}
        </div>

        {dropdownOpen ? (
          <ChevronUp
            onClick={toggleDropdown}
            className="chevron"
            size={25}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                toggleDropdown();
              }
            }}
          />
        ) : (
          <ChevronDown
            onClick={toggleDropdown}
            className="chevron"
            size={25}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                toggleDropdown();
              }
            }}
          />
        )}
      </div>
      {dropdownOpen && (
        <div className="ld-select-box">
          <div className="input-box">
            <input
              placeholder="Search..."
              value={filterText}
              onChange={(event) => setFilterText(event.target.value)}
              className="input"
            />
            {filterText && (
              <Close onClick={() => setFilterText("")} color="gray" size={25} />
            )}
          </div>
          <div className="list-container">
            {handleFilterSearch()?.length ? (
              handleFilterSearch()?.map((item, index) => (
                <div
                  key={`${item.entityID}-${index}`}
                  ref={(el) => (optionRefs.current[index] = el)}
                  tabIndex={0}
                  onClick={() => handleClick(item)}
                  onKeyDown={(e) =>
                    handleKeyDown(e, index, handleFilterSearch().length, item)
                  }
                  className="local-district-selects-container"
                >
                  <p className="select-text">{item.entityName}</p>
                  <p className="select-subtext">{item.multipleCountiesFlag}</p>
                </div>
              ))
            ) : (
              <div className="no-data">
                <p>No data available</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LocalDistrictSearchDropDown;
