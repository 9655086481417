import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IdahoStateSealImage from "../../styles/images/idahoStateSeal.webp";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import {
  AboutSection,
  ReportSection,
  StickyNav,
  VerticalCard,
  TransparentSearchBar,
} from "../../components";
import {
  ABOUT_SECTION_CONSTANTS,
  API_CONSTANTS,
  NAV_TAGS,
} from "../../types/constants";
import {
  DollarBillIcon,
  IdahoIcon,
  PayIcon,
  PopulationIcon,
} from "../../styles/icons";
import {
  getOpenGovReportsByPage,
  openGovPages,
} from "../../services/openGovReportService";
import { ReportsObject } from "../../types/interfaces/commonInterfaces";

const WorkforceSalaryLandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const aboutRef = useRef<HTMLDivElement>(null);
  const employeeCountRef = useRef<HTMLDivElement>(null);
  const highestPaidEmployeesRef = useRef<HTMLDivElement>(null);
  const employeePayRateRef = useRef<HTMLDivElement>(null);
  const additionalReportsRef = useRef<HTMLDivElement>(null);
  const tenureRef = useRef<HTMLDivElement>(null);
  const [workforceSalaryReports, setWorkforceSalaryReports] =
    useState<ReportsObject>();

  const tagToRefMap = {
    [NAV_TAGS.EMPLOYEE_COUNT]: employeeCountRef,
    [NAV_TAGS.EMPLOYEE_PAY_RATES]: employeePayRateRef,
    [NAV_TAGS.EMPLOYEE_HISTORY]: additionalReportsRef,
    [NAV_TAGS.EMPLOYEE_HIGHEST_PAID]: highestPaidEmployeesRef,
    [NAV_TAGS.TENURE]: tenureRef,
  };

  useEffect(() => {
    if (state && state.tag) {
      const refToScroll = tagToRefMap[state.tag];

      const stickyNavHeight =
        document.querySelector(".sticky-nav")?.clientHeight ?? 0;
      if (refToScroll?.current) {
        const refRect = refToScroll.current.getBoundingClientRect();
        const absoluteRefTop = refRect.top + window.scrollY;
        const scrollPosition = absoluteRefTop - stickyNavHeight;
        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }

      navigate(location.pathname, { replace: true });
    }
  }, [state, navigate, location.pathname, tagToRefMap]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleLoad = async () => {
      const data = await getOpenGovReportsByPage(openGovPages.WorkForceSalary);
      const reportsObject = data.reduce((acc: any, current: any) => {
        acc[current.Report_Name.trim()] = current;
        return acc;
      }, {});
      setWorkforceSalaryReports(reportsObject);
    };
    handleLoad();
  }, []);

  const employeeCountDescription = () => {
    return (
      <>
        <p className="employee-count-description">
          The Employee Count Report is a resource offering insights into the
          ongoing employment numbers of each agency and the overall state.
        </p>
        <strong>Please note:</strong> Current employee counts for universities
        include part-time non-benefitted student workers.
      </>
    );
  };

  return (
    <div className="landing-page-container landing-page-picture-background">
      <header>
        <Header />
      </header>
      <main>
        <div className="landing-page-hero-container">
          <TransparentSearchBar version={2} top="-3rem" />
          <span className="landing-page-hero-data">
            <div className="landing-page-hero-info">
              <img
                className="landing-page-idaho-state-seal"
                src={IdahoStateSealImage}
                alt="Idaho State Seal"
              />
              <span className="landing-page-explore-info-text">
                <h2 className="landing-page-hero-header">Workforce & Salary</h2>
                <h6 className="landing-page-hero-subheader">
                  Discover Idaho's state workforce and salaries with transparent
                  data, empowering citizens, job seekers, and policymakers.
                </h6>
                <div className="mobile-container">
                  <TransparentSearchBar version={2} mobile />
                </div>
              </span>
            </div>
            {/* <div className="landing-page-info-bubbles">
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderMedianSalary.svg`}
                infoDetailText={"Districts"}
                Icon={CountyIconBubbles}
                infoAmount={"832"}
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderEmployees.svg`}
                infoDetailText={"Types"}
                Icon={CountyIconBubbles}
                infoAmount={"38"}
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderWorkforceTenure.svg`}
                Icon={CountyIconBubbles}
                infoAmount={"49"}
                infoDetailText={"Agencies"}
              />
            </div> */}
          </span>
        </div>
        <StickyNav
          sections={[
            {
              icon: <IdahoIcon />,
              text: "About",
              ref: aboutRef,
            },
            {
              icon: <PayIcon />,
              text: "Employee Pay Rate",
              ref: employeePayRateRef,
            },
            {
              icon: <PopulationIcon />,
              text: "Employee Count",
              ref: employeeCountRef,
            },
          ]}
        />
        <AboutSection
          imageUrl={API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-8.png"}
          imageAltText={ABOUT_SECTION_CONSTANTS.WORKFORCE_SALARY.IMG_ALT}
          title={ABOUT_SECTION_CONSTANTS.WORKFORCE_SALARY.TITLE}
          description={ABOUT_SECTION_CONSTANTS.WORKFORCE_SALARY.DESCRIPTION}
          dataInfo={ABOUT_SECTION_CONSTANTS.WORKFORCE_SALARY.DATA_INFO}
          ref={aboutRef}
        />
        <div className="landing-page-reports-container landing-page-background">
          <div className="app-padding">
            <h4 className="landing-page-high-level-header">
              <DollarBillIcon />
              {` Explore Workforce`}
            </h4>
            <p className="landing-page-high-level-subheader body-large">
              Below you will be able to dive into statewide workforce data
              through various views we have provided.
            </p>
          </div>
          <span ref={employeePayRateRef}>
            <ReportSection
              scrollable
              title="Employee Pay Rate"
              description={
                "The Employee Pay Rate report provides access to the compensation details of over 35,000 state employees. Here, citizens can explore and understand the pay rates across various roles and state agencies."
              }
              reportId=""
              openGovReport={
                workforceSalaryReports?.["Employee Pay Rates"]?.Embed_Link ?? ""
              }
              newTabLink={
                workforceSalaryReports?.["Employee Pay Rates"]?.URLs ?? ""
              }
            />
          </span>
          <span ref={employeeCountRef}>
            <ReportSection
              scrollable
              title="Employee Count"
              descriptionElement={employeeCountDescription()}
              description=""
              reportId=""
              openGovReport={
                workforceSalaryReports?.["Employee Count"]?.Embed_Link ?? ""
              }
              newTabLink={
                workforceSalaryReports?.["Employee Count"]?.URLs ?? ""
              }
            />
          </span>
          <span ref={highestPaidEmployeesRef}>
            <ReportSection
              scrollable
              title="Highest Paid State Employees"
              description={`The Highest Paid State Employee Report shows how employee salaries match up across the State of Idaho.`}
              reportId=""
              openGovReport={
                workforceSalaryReports?.["Highest Paid"]?.Embed_Link ?? ""
              }
              newTabLink={workforceSalaryReports?.["Highest Paid"]?.URLs ?? ""}
            />
          </span>
          <span>
            <ReportSection
              scrollable
              title="Employee Pay Range"
              description={`The Employee Pay Range Report is a dynamic showcase of the diverse types of positions and their associated pay ranges for state employees. Gain an understanding of the spectrum of compensation, diverse salary structures, and varying pay scales within each agency.`}
              reportId=""
              openGovReport={
                workforceSalaryReports?.["Pay Range"]?.Embed_Link ?? ""
              }
              newTabLink={workforceSalaryReports?.["Pay Range"]?.URLs ?? ""}
            />
          </span>
          <span>
            <ReportSection
              scrollable
              title="Workforce Tenure"
              description={`The Workforce Tenure Report provides insight into the duration of service and tenure trends for state employees. Explore the collective experience of our workforce, track trends, and appreciate the longevity that contributes to the stability and expertise within Idaho's state agencies.`}
              reportId=""
              openGovReport={
                workforceSalaryReports?.["Workforce Tenure"]?.Embed_Link ?? ""
              }
              newTabLink={
                workforceSalaryReports?.["Workforce Tenure"]?.URLs ?? ""
              }
              ref={tenureRef}
            />
          </span>
          <div className="more-high-level-cards-container">
            <span>
              <h4 className="more-high-level-cards-header">
                Additional Workforce & Salary Reports
              </h4>
              <div className="more-high-level-cards">
                <a
                  href={
                    workforceSalaryReports?.["Employee History"]?.URLs ?? ""
                  }
                  target="_blank"
                  aria-label={`Link to ${workforceSalaryReports?.["Employee History"]?.Report_Name}`}
                  rel="noopener noreferrer"
                >
                  <VerticalCard
                    cardTitle={"Employee History"}
                    cardDescription="The employee history report gives a detailed work history of State of Idaho employees."
                    cardTag={NAV_TAGS.WORKFORCE_SALARY}
                    image={
                      API_CONSTANTS.imageBucket +
                      "/powerbi-images/sco-report-image-7.jpg"
                    }
                    readMoreText="Explore Data"
                  />
                </a>
                <a
                  href={
                    workforceSalaryReports?.["Projected Workforce Retirement"]
                      ?.URLs ?? ""
                  }
                  target="_blank"
                  aria-label={`Link to ${workforceSalaryReports?.["Projected Workforce Retirement"]?.Report_Name}`}
                  rel="noopener noreferrer"
                >
                  <VerticalCard
                    cardTitle={"Projected Workforce Retirement"}
                    cardDescription="The Projected Workforce Retirement report uses years of service and predetermined retirement eligibility data."
                    cardTag={NAV_TAGS.WORKFORCE_SALARY}
                    image={
                      API_CONSTANTS.imageBucket +
                      "/powerbi-images/sco-report-image-4.jpg"
                    }
                    readMoreText="Explore Data"
                  />
                </a>
                <a
                  href={workforceSalaryReports?.["Agency Head"]?.URLs ?? ""}
                  target="_blank"
                  aria-label={`Link to ${workforceSalaryReports?.["Agency Head"]?.Report_Name}`}
                  rel="noopener noreferrer"
                >
                  <VerticalCard
                    cardTitle="Agency Head"
                    cardDescription={`Explore the comprehensive salary details of every agency director within the State of Idaho.`}
                    cardTag={NAV_TAGS.WORKFORCE_SALARY}
                    image={
                      API_CONSTANTS.imageBucket +
                      "/powerbi-images/sco-report-image-8.jpg"
                    }
                    readMoreText="Explore Data"
                  />
                </a>
              </div>
            </span>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default WorkforceSalaryLandingPage;
