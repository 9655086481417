import { FC, memo } from "react";

const UrbanRenewalAgenciesIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="37"
        height="36"
        viewBox="0 0 37 36"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.85 31.5004L20.6375 23.2879L23.7875 20.1379L32 28.3504L28.85 31.5004ZM8.15 31.5004L5 28.3504L15.35 18.0004L12.8 15.4504L11.75 16.5004L9.8375 14.5879V17.6629L8.7875 18.7129L4.25 14.1754L5.3 13.1254H8.375L6.5 11.2504L11.825 5.92539C12.325 5.42539 12.8625 5.06289 13.4375 4.83789C14.0125 4.61289 14.6 4.50039 15.2 4.50039C15.8 4.50039 16.3875 4.61289 16.9625 4.83789C17.5375 5.06289 18.075 5.42539 18.575 5.92539L15.125 9.37539L17 11.2504L15.95 12.3004L18.5 14.8504L21.875 11.4754C21.775 11.2004 21.694 10.9129 21.632 10.6129C21.57 10.3129 21.5385 10.0129 21.5375 9.71289C21.5375 8.23789 22.044 6.99389 23.057 5.98089C24.07 4.96789 25.3135 4.46189 26.7875 4.46289C27.1625 4.46289 27.519 4.50039 27.857 4.57539C28.195 4.65039 28.5385 4.76289 28.8875 4.91289L25.175 8.62539L27.875 11.3254L31.5875 7.61289C31.7625 7.96289 31.8815 8.30639 31.9445 8.64339C32.0075 8.98039 32.0385 9.33689 32.0375 9.71289C32.0375 11.1879 31.5315 12.4319 30.5195 13.4449C29.5075 14.4579 28.2635 14.9639 26.7875 14.9629C26.4875 14.9629 26.1875 14.9379 25.8875 14.8879C25.5875 14.8379 25.3 14.7504 25.025 14.6254L8.15 31.5004Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
export default UrbanRenewalAgenciesIcon;
