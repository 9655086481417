import { FC, memo } from "react";

const TwitterIcon: FC<React.SVGProps<SVGSVGElement>> = memo(() => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.87448 0.237647L15.5411 2.57098C15.8478 2.67765 16.1678 2.66431 16.4611 2.55765L20.3678 1.03765C22.1145 0.357647 23.9945 1.63765 23.9945 3.51765V19.3176C23.9945 20.4643 23.2611 21.491 22.1811 21.851L16.8611 23.6376C16.3011 23.8243 15.6878 23.8243 15.1278 23.6243L8.46115 21.291C8.16781 21.1843 7.83448 21.1843 7.54115 21.3043L3.63448 22.8243C1.88781 23.5043 0.0078125 22.2243 0.0078125 20.3443V4.54431C0.0078125 3.39765 0.741146 2.38431 1.82115 2.01098L7.14115 0.224314C7.70115 0.0376473 8.31448 0.0376473 8.87448 0.237647ZM8.00781 18.3043L16.0078 21.1176V5.55765L8.00781 2.74431V18.3043Z"
        fill="#404E65"
      />
    </svg>
  );
});

export default TwitterIcon;
