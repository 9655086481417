import { PowerBIEmbed } from "powerbi-client-react";
import { FC, useEffect, useState } from "react";
import { models, Report, Embed, Page, VisualDescriptor } from "powerbi-client";
import { getPowerBiEmbedToken } from "./PowerBi.service";
import "./powerBi.scss";
import { LOCAL_DISTRICT_SPECIFIC_REPORTS } from "../../types/constants/powerBiConstants";

export type SlicerFilter = {
  visualName: string;
  slicerState: models.ISlicerState;
};

type PowerBiProps = {
  reportId: string;
  slicerFilters?: SlicerFilter[];
  fullScreen?: boolean;
  setReportLoaded: () => void;
  reportLoaded: boolean;
};

type reportData = {
  embedUrl: string;
  embedToken: string;
  expiry: string;
};

const PowerBi: FC<PowerBiProps> = ({
  reportId,
  slicerFilters,
  fullScreen = false,
  reportLoaded,
  setReportLoaded,
}) => {
  const [reportData, setReportData] = useState<reportData>();
  const [report, setReport] = useState<Report>();

  const TOKEN_EXPIRY_CHECK_INTERVAL = 1000 * 60 * 2;
  const TOKEN_EXPIRY_THRESHOLD = 1000 * 60 * 5;

  useEffect(() => {
    const intervalId = setInterval(
      checkForTokenExpiry,
      TOKEN_EXPIRY_CHECK_INTERVAL
    );
    return () => clearInterval(intervalId);
  }, []);

  const handleVisibilityChange = () => {
    if (!document.hidden) {
      checkForTokenExpiry();
    }
  };

  useEffect(() => {
    const onLoad = () => {
      handleEmbedToken();
      document.addEventListener("visibilitychange", handleVisibilityChange);
    };
    onLoad();

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleEmbedToken = async () => {
    const data = await getPowerBiEmbedToken(reportId);
    setReportData(data);
  };

  const checkForTokenExpiry = () => {
    if (!reportData) {
      return;
    }
    const tokenExpiry = Date.parse(reportData.expiry);
    const currentTime = Date.now();
    const timeLeft = tokenExpiry - currentTime;
    if (timeLeft <= TOKEN_EXPIRY_THRESHOLD) {
      updateReportEmbedToken();
    }
  };

  const updateReportEmbedToken = async () => {
    const data = await getPowerBiEmbedToken(reportId);
    report?.setAccessToken(data.embedToken);
  };

  useEffect(() => {
    if (report && slicerFilters?.length) {
      updateSlicerByVisualName();
    }
  }, [report, slicerFilters]);

  //eslint-disable-next-line
  report?.on("loaded", (event) => {
    if (slicerFilters?.length) {
      updateSlicerByVisualName();
    }
    report.render();
  });

  report?.on("rendered", (event) => {
    setReportLoaded();
  });

  //eslint-disable-next-line
  report?.on("error", (event) => {
    // console.error('report error', event);
  });

  const updateSlicerByVisualName = async () => {
    try {
      if (slicerFilters?.length) {
        const visualNames = [
          LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictBudget
            .visualNameEntityName,
          LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictFundBalance
            .visualNameEntityName,
          LOCAL_DISTRICT_SPECIFIC_REPORTS.LocalDistrictSalaries
            .visualNameEntityName,
        ];
        const visuals = await getVisuals();
        if (window.location.pathname.includes("LocalDistrict")) {
          const entityNames: any = slicerFilters[1].slicerState.filters[0];
          const isEntityUndefined = entityNames?.values.some(
            (name: any) => name === undefined
          );
          if (isEntityUndefined) {
            for (const visualName of visualNames) {
              const visual = visuals.find((v) => v.name === visualName);
              if (visual) {
                await visual.setSlicerState({ filters: [] });
              }
            }
          }
        }
        //eslint-disable-next-line
        slicerFilters.forEach(async (sf) => {
          const visualToUpdate = visuals.find(
            (v: VisualDescriptor) => v.name === sf.visualName
          );
          await visualToUpdate?.setSlicerState(sf.slicerState);
        });
        // getSlicerFilters();
      }
    } catch {
      // console.error('error updating slicer:', err);
    }
  };

  const getVisuals = async () => {
    const activePage = await getActivePage();
    const visuals = await activePage.getVisuals();
    return visuals;
  };

  // const getSlicerFilters = async () => {
  //   const activePage = await getActivePage();
  //   const slicers = await activePage.getSlicers();
  //   console.log("slicer " + reportId, slicers);
  //   const slicerStates = await Promise.all(
  //     slicers.map((s: any) => s.getSlicerState())
  //   );
  //   console.log("slicer states " + reportId, slicerStates);
  // };

  const getActivePage = async () => {
    const pages = await report?.getPages();
    const activePage = pages?.filter(function (page) {
      return page.isActive;
    })[0];
    return activePage as Page;
  };

  const handleClassName = () => {
    return fullScreen
      ? "full-screen-report power-bi-container "
      : "power-bi-container ";
  };

  return reportData?.embedToken ? (
    <PowerBIEmbed
      phasedEmbedding
      cssClassName={`${handleClassName() || ""}`}
      embedConfig={{
        permissions: 0,
        type: "report",
        id: reportId,
        embedUrl: reportData.embedUrl,
        accessToken: reportData.embedToken,
        tokenType: models.TokenType.Embed,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          background: models.BackgroundType.Transparent,
          layoutType: models.LayoutType.Custom,
          customLayout: {
            displayOption: models.DisplayOption.FitToWidth,
          },
        },
      }}
      //   eventHandlers={
      //     new Map([
      //       ["loaded", function () {}],
      //       ["rendered", function (event) {}],
      //       ["error", function (event) {}],
      //     ])
      //   }
      getEmbeddedComponent={async (embeddedReport: Embed) => {
        setReport(embeddedReport as Report);
      }}
    />
  ) : null;
};

export default PowerBi;
