import { FC, RefObject } from "react";
import "./stickyNav.scss";
import { useAppContext } from "../../context/AppContext";

type StickyNavProps = {
  sections: StickyNavSection[];
};

type StickyNavSection = {
  icon: React.ReactNode;
  text: string;
  ref?: RefObject<HTMLDivElement>;
};

const StickyNav: FC<StickyNavProps> = ({ sections }) => {
  function handleOnClick(ref?: RefObject<HTMLDivElement>) {
    const stickyNavHeight =
      document.querySelector(".sticky-nav")?.clientHeight ?? 0;
    if (ref?.current) {
      const refRect = ref.current.getBoundingClientRect();
      const absoluteRefTop = refRect.top + window.scrollY;
      const scrollPosition = absoluteRefTop - stickyNavHeight;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }

  const { bannerHeight } = useAppContext();

  return (
    <>
      <span className="hero-separator"></span>
      <div className="sticky-nav" style={{ top: bannerHeight }}>
        {sections?.map(({ text, icon, ref }, index) => {
          return (
            <div
              className="section"
              key={index + text}
              onClick={() => handleOnClick(ref)}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleOnClick(ref);
                }
              }}
              aria-label={`Navigate to ${text}`}
            >
              <div className="icon-text-container">
                <div className="icon" role="button" tabIndex={-1}>
                  {icon}
                </div>
                <p>{text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default StickyNav;
