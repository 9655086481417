import { Link, useNavigate } from "react-router-dom";
import { HomePageVerticalCards } from "../../HomePage";
import { GradientButton, VerticalCard } from "../../../../components";
import { HOME_PAGE_CONSTANTS } from "../../../../types/constants";
import "./homePageVerticalCardsSection.scss";

type CardsSectionProps = {
  sectionTitle: string;
  sectionSubTitle: string;
  sectionTag: string;
  sectionData: HomePageVerticalCards[];
  navTag: string;
};

const HomePageVerticalCardsSection = ({
  sectionTitle,
  sectionSubTitle,
  sectionTag,
  sectionData,
}: CardsSectionProps) => {
  const navigate = useNavigate();

  return (
    <div className="cards-section-container">
      <div className="section-gutter">
        <div className="section-header">
          <div tabIndex={0}>
            <h4
              className="section-title"
              aria-label={`${sectionTitle} section`}
            >
              {sectionTitle}
            </h4>
            <p className="section-subtitle body-large">{sectionSubTitle}</p>
          </div>
          <div>
            <Link
              style={{ textDecoration: "none" }}
              to={`${sectionTag}`}
              tabIndex={-1}
            >
              <GradientButton
                buttonSize="button-text-large"
                ButtonAction={() => null}
                buttonText={HOME_PAGE_CONSTANTS.buttons.EXPLORE_MORE}
              />
            </Link>
          </div>
        </div>
        <div className="wrapper">
          {sectionData.map((data, index) => (
            <VerticalCard
              key={data.TITLE}
              cardTitle={data.TITLE}
              cardDescription={data.DESCRIPTION}
              cardTag={sectionTag}
              comingSoon={data.COMING_SOON}
              image={data.IMAGE}
              readMoreAction={() =>
                navigate(data.NAVIGATION_LINK, {
                  state: { tag: data.NAVIGATION_TAG },
                })
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePageVerticalCardsSection;
