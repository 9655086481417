import { FC, memo } from "react";

const RecreationDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1333 3.16328C13.0607 3.06425 12.9658 2.98371 12.8563 2.9282C12.7468 2.87268 12.6258 2.84375 12.503 2.84375C12.3803 2.84375 12.2592 2.87268 12.1497 2.9282C12.0403 2.98371 11.9454 3.06425 11.8728 3.16328L6.14367 10.9758C6.05814 11.0922 6.00657 11.2301 5.9947 11.3741C5.98283 11.5181 6.01111 11.6625 6.07641 11.7914C6.14171 11.9203 6.24147 12.0285 6.3646 12.1041C6.48773 12.1797 6.6294 12.2196 6.77388 12.2195H8.40825L4.56867 17.7643C4.48747 17.8816 4.43992 18.0188 4.43116 18.1611C4.42241 18.3035 4.45278 18.4455 4.519 18.5718C4.58521 18.6981 4.68474 18.8039 4.80679 18.8777C4.92884 18.9515 5.06876 18.9904 5.21138 18.9904H11.7218V22.3758C11.7218 22.583 11.8041 22.7817 11.9506 22.9282C12.0971 23.0747 12.2958 23.157 12.503 23.157C12.7102 23.157 12.909 23.0747 13.0555 22.9282C13.202 22.7817 13.2843 22.583 13.2843 22.3758V18.9904H19.7947C19.9373 18.9904 20.0772 18.9515 20.1993 18.8777C20.3213 18.8039 20.4209 18.6981 20.4871 18.5718C20.5533 18.4455 20.5837 18.3035 20.5749 18.1611C20.5662 18.0188 20.5186 17.8816 20.4374 17.7643L16.5968 12.2195H18.2322C18.3767 12.2196 18.5184 12.1797 18.6415 12.1041C18.7646 12.0285 18.8644 11.9203 18.9297 11.7914C18.995 11.6625 19.0233 11.5181 19.0114 11.3741C18.9995 11.2301 18.9479 11.0922 18.8624 10.9758L13.1333 3.16328Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default RecreationDistrictIcon;
