import { FC, useEffect, useState } from "react";
import {
  EntityListCard,
  SearchDropDownComponent,
  YearTagPill,
} from "../../../components";
import { EntityFinancialFilter } from "../../../types/interfaces/entityInterfaces";
import { getCitiesFinancial } from "../cityLandingPage.service";

type CityFinancialTableProps = {
  fiscalYears: string[];
};

const CityFinancialTable: FC<CityFinancialTableProps> = ({ fiscalYears }) => {
  const [searchListData, setSearchListData] =
    useState<EntityFinancialFilter[]>();
  const [selectedYear, setSelectedYear] = useState<string>("2024");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState<EntityFinancialFilter>();
  const [tabIndexForPill, setTabIndexForPill] = useState(0);

  useEffect(() => {
    const getCitiesCall = async () => {
      setSearchListData([]);
      setIsLoading(true);
      try {
        const res: EntityFinancialFilter[] = await getCitiesFinancial(
          selectedYear
        );
        setSearchListData(() => res);
        handleSelectedItem(res);
      } finally {
        setIsLoading(false);
      }
    };
    getCitiesCall();
  }, [selectedYear, setSelectedYear]);

  const handleSelectedItem = (cities: EntityFinancialFilter[]) => {
    if (!selectedItem || !cities.some((city) => city.id === selectedItem.id)) {
      setSelectedItem(cities[0]);
    } else {
      setSelectedItem(cities.find((city) => city.id === selectedItem.id));
    }
  };

  return (
    <>
      <div className="pill-container">
        {fiscalYears?.map((year) => (
          <div
            tabIndex={tabIndexForPill}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                const targetElement = e.target as HTMLElement;
                const yearText =
                  targetElement.querySelector(".tag-pill")?.textContent;

                if (yearText) {
                  const year = yearText.replace("YEAR ", "");
                  setSelectedYear(year);
                }
                setTabIndexForPill(-1);
              }

              if (e.key === "Escape") {
                const activeEl = document.activeElement as
                  | HTMLElement
                  | SVGElement;
                if (activeEl?.blur) {
                  activeEl.blur();
                }
              }
            }}
            onBlur={() => setTabIndexForPill(0)}
          >
            <YearTagPill
              key={year}
              selectedYear={selectedYear}
              year={year}
              setSelectedYear={setSelectedYear}
            />
          </div>
        ))}
      </div>
      <h6 className="city-entity-search-menu-header">Search City</h6>
      <div className="search-container">
        <SearchDropDownComponent
          isEntitiesFiltered={true}
          entitiesData={searchListData}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>
      <div className="filter-table auto-height-table">
        {searchListData?.length ? (
          selectedItem && (
            <EntityListCard
              isLoading={isLoading}
              entityName={selectedItem.name}
              actualExpenditures={selectedItem.expenseActual}
              actualRevenue={selectedItem.revenueActual}
              budgetedExpenditures={selectedItem.expenseBudget}
              budgetedRevenue={selectedItem.revenueBudget}
              reportDocs={selectedItem.reportDocs}
            />
          )
        ) : (
          <EntityListCard
            noData
            entityName=""
            isLoading={isLoading}
            actualExpenditures={""}
            actualRevenue={""}
            budgetedExpenditures={""}
            budgetedRevenue={""}
          />
        )}
      </div>
    </>
  );
};

export default CityFinancialTable;
