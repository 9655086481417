import "./gradientButton.scss";

type ButtonProps = {
  buttonText: string;
  buttonSize: string;
  ButtonAction?: () => void;
  megaMenu?: boolean;
};

const GradientButton = ({
  buttonText,
  ButtonAction,
  buttonSize,
  megaMenu,
}: ButtonProps) => {
  return (
    <button
      onClick={ButtonAction}
      id="button"
      className={
        buttonSize + (megaMenu ? " button-container-menu" : " button-container")
      }
    >
      {buttonText}
    </button>
  );
};

export default GradientButton;
