import { FC, useEffect, useState } from "react";
import {
  EntityListCard,
  SearchDropDownComponent,
  YearTagPill,
} from "../../../components";
import { handleTypeIcon } from "../../../types/enums/iconsEnum";
import {
  EntityFilter,
  EntityFinancialFilter,
} from "../../../types/interfaces/entityInterfaces";
import {
  getCountyEntityTypes,
  getCountyEntitiesByEntityTypeID,
} from "../countyPage.service";
import { requestFiscalYearList } from "../../../api/searchType.api";
import { ENTITY_TYPE_IDS } from "../../../types/constants";

type CountyEntityFinancialTableProps = {
  countyId: string;
};

const CountyEntityFinancialTable: FC<CountyEntityFinancialTableProps> = ({
  countyId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fiscalYears, setFiscalYears] = useState<string[]>([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<string>("2024");
  const [countyEntityTypes, setCountyEntityTypes] =
    useState<EntityFinancialFilter[]>();
  const [countyEntities, setCountyEntities] =
    useState<EntityFinancialFilter[]>();
  const [selectedEntityType, setSelectedEntityType] = useState<EntityFilter>();
  const [tabIndexForPill, setTabIndexForPill] = useState(0);

  useEffect(() => {
    const handleLoad = async () => {
      handleCountyEntities();
      const fiscalYears = await requestFiscalYearList();
      setFiscalYears(fiscalYears);
    };
    handleLoad();
  }, []);

  useEffect(() => {
    handleEntitiesByType();
  }, [selectedEntityType, selectedFiscalYear, countyId]);

  const handleEntitiesByType = async () => {
    setIsLoading(true);
    if (selectedEntityType?.type && countyId) {
      const res: EntityFinancialFilter[] =
        await getCountyEntitiesByEntityTypeID(
          selectedEntityType?.type,
          countyId,
          selectedFiscalYear
        );
      const filteredRes = res.map((entity) => {
        if (entity.reportDocs) {
          entity.reportDocs = entity.reportDocs.filter((doc) => {
            return doc.Kind !== "Annual";
          });
        }
        return entity;
      });
      setCountyEntities(filteredRes);
    }
    setIsLoading(false);
  };

  const handleCountyEntities = async () => {
    if (countyId) {
      const res: EntityFinancialFilter[] = await getCountyEntityTypes(countyId);
      setCountyEntityTypes(res);
      setSelectedEntityType(
        res?.find((item) => item.type === ENTITY_TYPE_IDS.FIRE_DISTRICT)?.name
          ? res?.find((item) => item.type === ENTITY_TYPE_IDS.FIRE_DISTRICT)
          : res[0]
      );
    }
  };

  return (
    <>
      <div className="pill-container">
        {fiscalYears?.map((year) => (
          <div
            tabIndex={tabIndexForPill}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                const targetElement = e.target as HTMLElement;
                const yearText =
                  targetElement.querySelector(".tag-pill")?.textContent;

                if (yearText) {
                  const year = yearText.replace("YEAR ", "");
                  setSelectedFiscalYear(year);
                }
                setTabIndexForPill(-1);
              }

              if (e.key === "Escape") {
                const activeEl = document.activeElement as
                  | HTMLElement
                  | SVGElement;
                if (activeEl?.blur) {
                  activeEl.blur();
                }
              }
            }}
            onBlur={() => setTabIndexForPill(0)}
          >
            <YearTagPill
              key={year}
              selectedYear={selectedFiscalYear}
              setSelectedYear={setSelectedFiscalYear}
              year={year}
            />
          </div>
        ))}
      </div>
      <h6 className="county-entity-search-menu-header">Search Entity</h6>
      <div className="search-component-container">
        <SearchDropDownComponent
          entitiesData={countyEntityTypes}
          selectedItem={selectedEntityType}
          setSelectedItem={setSelectedEntityType}
          isEntitiesFiltered={true}
        />
      </div>
      <div className="filter-table">
        {countyEntities?.length ? (
          selectedEntityType &&
          countyEntities?.map((data: EntityFinancialFilter) => (
            <EntityListCard
              isLoading={isLoading}
              reportDocs={data.reportDocs}
              key={data.id}
              Icon={handleTypeIcon(selectedEntityType.type)}
              entityName={data.name}
              actualExpenditures={data.expenseActual}
              actualRevenue={data.revenueActual}
              budgetedExpenditures={data.expenseBudget}
              budgetedRevenue={data.revenueBudget}
            />
          ))
        ) : (
          <EntityListCard
            noData
            entityName=""
            actualExpenditures={""}
            actualRevenue={""}
            isLoading={isLoading}
            budgetedExpenditures={""}
            budgetedRevenue={""}
          />
        )}
      </div>
    </>
  );
};

export default CountyEntityFinancialTable;
