import { FC } from "react";
import { HOME_PAGE_CONSTANTS } from "../../../../types/constants";
import { IdahoCountiesMap, TransparentSearchBar } from "../../../../components";
import "../../../countiesLandingPage/countiesLandingPage.scss";
import "./homePageHero.scss";

type HomePageHeroProp = {
  exploreDataRef: React.RefObject<HTMLDivElement>;
  hoveredCounty: string;
  setHoveredCounty: React.Dispatch<React.SetStateAction<string>>;
};

const HomePageHero: FC<HomePageHeroProp> = (props: HomePageHeroProp) => {
  const { exploreDataRef, hoveredCounty, setHoveredCounty } = props;
  return (
    <div className="home-page-hero-section">
      <TransparentSearchBar version={0} top={"-10rem"} />
      <div className="hero-info" tabIndex={0}>
        <h1>
          <span>{HOME_PAGE_CONSTANTS.homePageHero.WELCOME_TO}</span>
          <br />
          {HOME_PAGE_CONSTANTS.homePageHero.TRANSPARENT_IDAHO}
        </h1>
        <p className="body-extra-large">
          {HOME_PAGE_CONSTANTS.homePageHero.SUB_TEXT}
        </p>
      </div>
      <div className="explore-idaho-button">
        <IdahoCountiesMap
          hoveredCounty={hoveredCounty}
          setHoveredCounty={setHoveredCounty}
        />
      </div>
      <div className="mobile-container">
        <TransparentSearchBar version={0} mobile />
      </div>
    </div>
  );
};

export default HomePageHero;
