import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import IdahoStateSeal from "../../styles/images/idahoStateSeal.webp";
import {
  CountyIconBubbles,
  IdahoIcon,
  UniformAccountingManualIcon,
  UrbanRenewalAgenciesIcon,
} from "../../styles/icons";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import {
  AboutSection,
  DownloadFileComponent,
  InfoBubble,
  LoadingBar,
  LoadingState,
  StickyNav,
  UAMSection,
  YearTagPill,
} from "../../components";

import {
  NAV_TAGS,
  ENTITY_TYPE_IDS,
  API_CONSTANTS,
  ABOUT_SECTION_CONSTANTS,
} from "../../types/constants";

import { EntityFinancialFilter } from "../../types/interfaces/entityInterfaces";
import { getCountyEntitiesByEntityTypeID } from "../countyPage/countyPage.service";
import { requestFiscalYearList } from "../../api/searchType.api";

import "../landingPages.scss";
import "./localDistrictsLandingPage.scss";
import LocalDistrictSearchComponent from "./localDistrictComponents/LocalDistrictSearchComponent";

const LocalDistrictsLandingPage = () => {
  const [fiscalYears, setFiscalYears] = useState<string[]>([]);
  const [urbanRenewalYear, setUrbanRenewalYear] = useState<string>("2024");
  const [scrollToElement, setScrollToElement] = useState<string>("");
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.InProgress
  );
  const [urbanRenewalPdfList, setUrbanRenewalPdfList] =
    useState<EntityFinancialFilter[]>();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const exploreDataRef = useRef<HTMLDivElement>(null);
  const allLocalDistrictsRef = useRef<HTMLDivElement>(null);
  const urbanRenewalRef = useRef<HTMLDivElement>(null);
  const UniformAccountingRef = useRef<HTMLDivElement>(null);
  const [tabIndexForPill, setTabIndexForPill] = useState(0);

  const tagToRefMap = {
    [NAV_TAGS.EXPLORE_LOCAL_DISTRICT_DATA]: exploreDataRef,
    [NAV_TAGS.LOCAL_DISTRICT_ALL_LOCAL_DISTRICT_BUDGETS]: allLocalDistrictsRef,
    [NAV_TAGS.Local_DISTRICT_URBAN_RENEWAL]: urbanRenewalRef,
  };

  useEffect(() => {
    if (state && state.tag) {
      const refToScroll = tagToRefMap[state.tag];
      refToScroll.current?.scrollIntoView({ behavior: "smooth" });
      navigate(location.pathname, { replace: true });
      setScrollToElement("");
    }
    if (location.hash) {
      const sectionId = location.hash.replace("#", "");
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
        const headerOffset = 40;
        const elementPosition = sectionElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
          top: window.scrollY + offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [state, navigate, location.pathname, tagToRefMap, location.hash]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleLoad = async () => {
      const fiscalYear = await requestFiscalYearList();
      setFiscalYears(fiscalYear);
    };

    handleLoad();
  }, []);

  useEffect(() => {
    const getUrbanRenewalList = async () => {
      setLoadingState(LoadingState.InProgress);
      setUrbanRenewalPdfList([]);
      const res: EntityFinancialFilter[] =
        await getCountyEntitiesByEntityTypeID(
          ENTITY_TYPE_IDS.URBAN_RENEWAL,
          "0",
          urbanRenewalYear
        );

      const filteredRes = res.map((entity) => {
        if (entity.reportDocs) {
          entity.reportDocs = entity.reportDocs.filter((doc) => {
            return doc.Kind === "Annual";
          });
        }
        return entity;
      });
      setUrbanRenewalPdfList(filteredRes);
      setLoadingState(LoadingState.Finished);
    };
    getUrbanRenewalList();
  }, [urbanRenewalYear]);

  const renderUrbanRenewalReports = () => {
    const noReports = urbanRenewalPdfList?.every(
      (item) => !item.reportDocs || item.reportDocs.length === 0
    );

    if (loadingState === LoadingState.InProgress)
      return <div className="body-large no-reports-message">Loading...</div>;

    if (
      (loadingState === LoadingState.Finished || LoadingState.Idle) &&
      noReports
    ) {
      return <p className="body-large no-reports-message">Coming Soon!</p>;
    }

    return urbanRenewalPdfList?.map((item) =>
      item.reportDocs && item.reportDocs.length > 0 ? (
        <DownloadFileComponent
          key={item.reportDocs[0].ReportName}
          url={item.reportDocs[0].Url}
          fileName={item.name}
        />
      ) : null
    );
  };

  return (
    <div className="landing-page-container landing-page-picture-background">
      {
        <LoadingBar
          loadingState={loadingState}
          setLoadingState={setLoadingState}
        />
      }
      <header>
        <Header />
      </header>
      <main>
        <div className="landing-page-hero-container">
          <span className="landing-page-hero-data">
            <div className="landing-page-hero-info" tabIndex={0}>
              <img
                className="landing-page-idaho-state-seal"
                src={IdahoStateSeal}
                alt="Idaho State Seal"
              />
              <span className="landing-page-explore-info-text">
                <h1 className="landing-page-hero-header">
                  Explore Local Districts
                </h1>
                <p className="landing-page-hero-subheader">
                  Idaho consists of roughly 979 local districts providing
                  services ranging from ambulance and fire to mosquito abatement
                </p>
              </span>
            </div>
            <div className="landing-page-info-bubbles">
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderTaxingDistrict.svg`}
                infoDetailText={"Districts"}
                Icon={CountyIconBubbles}
                infoAmount={"979"}
                bottomHeader={`
                2023 TAX COMMISSION`}
                infoBubbleName="Taxing Districts"
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderEntityTypes.svg`}
                infoDetailText={"Types"}
                Icon={CountyIconBubbles}
                infoAmount={"36"}
                bottomHeader={"FROM LOCAL GOVERNMENT REGISTRY"}
                infoBubbleName="Entity Types"
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderUrbanRenewal.svg`}
                Icon={CountyIconBubbles}
                infoAmount={"49"}
                infoDetailText={"Agencies"}
                bottomHeader={"FROM LOCAL GOVERNMENT REGISTRY"}
                infoBubbleName="Urban Renewal"
              />
            </div>
          </span>
        </div>
        <div ref={exploreDataRef}>
          <StickyNav
            sections={[
              {
                icon: <IdahoIcon />,
                text: "Local Districts",
                ref: allLocalDistrictsRef,
              },
              {
                icon: <UrbanRenewalAgenciesIcon />,
                text: "Urban Renewal Agency \n\nAnnual Report",
                ref: urbanRenewalRef,
              },
              {
                icon: <UniformAccountingManualIcon />,
                text: "Uniform Accounting \n\n& Reporting Manual",
                ref: UniformAccountingRef,
              },
            ]}
          />
          <div tabIndex={0}>
            <AboutSection
              imageUrl={
                API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-2.png"
              }
              imageAltText={ABOUT_SECTION_CONSTANTS.LOCAL_DISTRICTS.IMG_ALT}
              title={ABOUT_SECTION_CONSTANTS.LOCAL_DISTRICTS.TITLE}
              description={ABOUT_SECTION_CONSTANTS.LOCAL_DISTRICTS.DESCRIPTION}
              dataInfo={ABOUT_SECTION_CONSTANTS.LOCAL_DISTRICTS.DATA_INFO}
              scrollDescriptionToElement={scrollToElement}
            />
          </div>
        </div>
        <div
          className="local-district-search-section"
          ref={allLocalDistrictsRef}
        >
          <h1
            className="districts-entity-search-header"
            tabIndex={0}
            aria-label="Search Local Districts Section."
          >
            SEARCH LOCAL DISTRICTS
          </h1>
          <p className="districts-entity-search-subheader body-large">
            {
              "Use the search tool to find district-specific information on budgets, revenues, expenditures, and more. First, select an entity type to narrow your search. Then, you can either filter by county or search by a specific district name to view relevant data.\n\n If you're interested in viewing more comprehensive data on districts by county, click "
            }
            <Link
              className="local-district-here-link"
              to={"/Counties#explore-couties-section"}
              aria-label="View comprehensive district data by county"
            >
              {"here "}
            </Link>
            {"to access the county page and select a specific county."}
          </p>
          <LocalDistrictSearchComponent />
        </div>
        <div className="ld-download-pdfs-section">
          <div
            tabIndex={0}
            aria-label="Urban Renewal Agency Annual Report Section. Download annual reports for each Urban Renewal Agency by choosing a year and clicking on an agency. To learn more about Urban Renewal Agencies, click on the link below."
          >
            <h1
              className="urban-renewal-header"
              ref={urbanRenewalRef}
              id="urbanRenewalRef"
            >
              URBAN RENEWAL AGENCY ANNUAL REPORT
            </h1>
            <p className="urban-renewal-subheader body-large">
              {
                "Download annual reports for each Urban Renewal Agency (URA) by choosing a year and clicking on an agency. \n\n Please note: We display all reports submitted by Urban Renewal Districts. However, some agencies may not respond, and the list of non-responsive agencies may change each year. \n\nYou can learn more about Urban Renewal Agencies "
              }
              <Link
                className="local-district-here-link"
                to={"/LocalDistrict"}
                state={{ tag: NAV_TAGS.EXPLORE_LOCAL_DISTRICT_DATA }}
                onClick={() => setScrollToElement("strong")}
                aria-label="Internal link. CLick to learn more about Urban Renewal Agencies"
              >
                {"here."}
              </Link>
            </p>
          </div>
          <div
            className="pill-container"
            aria-label="Select a year to view reports"
            tabIndex={0}
          >
            {fiscalYears?.map((year) => (
              <div
                tabIndex={tabIndexForPill}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    const targetElement = e.target as HTMLElement;
                    const yearText =
                      targetElement.querySelector(".tag-pill")?.textContent;

                    if (yearText) {
                      const year = yearText.replace("YEAR ", "");
                      setUrbanRenewalYear(year);
                    }
                    setTabIndexForPill(-1);
                  }

                  if (e.key === "Escape") {
                    const activeEl = document.activeElement as
                      | HTMLElement
                      | SVGElement;
                    if (activeEl?.blur) {
                      activeEl.blur();
                    }
                  }
                }}
                onBlur={() => setTabIndexForPill(0)}
              >
                <YearTagPill
                  key={year}
                  selectedYear={urbanRenewalYear}
                  year={year}
                  setSelectedYear={setUrbanRenewalYear}
                />
              </div>
            ))}
          </div>
          <div className="ld-downloads-list-container">
            {renderUrbanRenewalReports()}
          </div>
        </div>
        <div ref={UniformAccountingRef}>
          <UAMSection />
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default LocalDistrictsLandingPage;
