import React, {
  FC,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import "./transparentSearchBar.scss";

type TransparentSearchBarProps = {
  version?: number;
  top?: string;
  mobile?: boolean;
};



const TransparentSearchBar: FC<TransparentSearchBarProps> = ({
  version,
  top,
  mobile = false,
}) => {
  const defaultClassName = "transparent-search-bar";
  const mobileClassName = "transparent-search-bar mobile";
  const [className, setClassName] = useState(
    mobile ? `${mobileClassName} hide-iframe` : `${defaultClassName} hide-iframe`
  );

  const [shouldLoad, setShouldLoad] = useState(false);
  const [isError, setIsError] = useState(false);


  const iframeRef = useRef<HTMLIFrameElement>(null);

  const ErrorBanner: FC = () => {
    return (
      <div className={`search-bar-error ${className}`}
      style={{ top }}
      >
        <p>
          <b>Search Bar Outage: </b>
          {`Access reports directly here for `}
          <a href="https://idaho.opengov.com/transparency-reporting/idaho/0b470160-e568-4c8d-8769-d3f32fc88b10/35de0f96-3f01-4f0c-8961-f624755d77ca?savedViewId=bd748b2e-e47b-4f9e-8121-7270034d8435">
             State Workforce
          </a>
          {` and `}
           <a href="https://idaho.opengov.com/transparency-reporting/idaho/2b17eed6-3282-4416-ab38-656795512745/1d85032b-6b1a-4b21-8a57-9f623deceed4?savedViewId=87e710d0-1c55-4548-8341-396868936d02">
           Vendor Payments
           </a>
           </p>
      </div>
    );
  }

  const handleClassName = (isHidden: boolean) => {
    const classType = mobile ? mobileClassName : defaultClassName;
    isHidden
      ? setClassName(`hide-iframe ${classType}`)
      : setClassName(classType);
  };

  const checkInView = useCallback(() => {
    if (!iframeRef.current) return;
    const rect = iframeRef.current.getBoundingClientRect();
    const inView = rect.top < window.innerHeight && rect.bottom > 0;
    if (inView) setShouldLoad(true);
  }, []);

  useEffect(() => {
    let timer: number;
    function handleScroll() {
      if (timer) window.clearTimeout(timer);
      timer = window.setTimeout(checkInView, 100);
    }
    window.addEventListener("scroll", handleScroll);
    checkInView();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timer) window.clearTimeout(timer);
    };
  }, [checkInView]);

  return (
    isError ? <ErrorBanner /> :
    <iframe
      ref={iframeRef}
      className={className}
      style={{ top }}
      onError={() => setIsError(true)}
      
      scrolling="no"
      onMouseEnter={() => handleClassName(false)}
      onMouseLeave={() => handleClassName(true)}
      src={
        shouldLoad
          ? version
            ? `https://transparencyresources.idaho.gov/customsearch/${version}`
            : "https://transparencyresources.idaho.gov/customsearch"
          : undefined
      }
    />
  );
};

export default TransparentSearchBar;
