import { SlicerFilter } from "../powerBi/PowerBi";
import DownloadDataIcon from "../../styles/icons/DownloadDataIcon";
import { forwardRef, MouseEventHandler, useState } from "react";
import { API_CONSTANTS } from "../../types/constants";
import { CheveronLeft, CheveronRight } from "styled-icons/zondicons";
import WarningIcon from "../../styles/icons/warningIcon";
import { LazyLoadPowerBi } from "../lazyLoads";
import "./reportSection.scss";

type ReportSectionProps = {
  title: string;
  description: string | React.ReactNode;
  descriptionElement?: JSX.Element;
  descriptionLink?: {
    title: string;
    link: string;
  };
  descriptionBold?: string;
  reportContainerStyle?: string;
  reportTitleStyle?: string;
  reportDescStyle?: string;
  reportId: string;
  powerBiPlaceholderImg: string;
  slicerFilters: SlicerFilter[];
  scrollable?: boolean;
  comingSoon?: boolean;
  comingSoonImageUrl?: string;
  altDownloadLink?: string;
  openGovReport?: string;
  newTabLink?: string;
  onBackTap: MouseEventHandler<HTMLDivElement>;
  onForwardTap: MouseEventHandler<HTMLDivElement>;
  downloadTextContainerStyle?: string;
  pageName?: string;
};

const ReportSectionComponent = forwardRef<HTMLDivElement, ReportSectionProps>(
  (props, ref) => {
    const {
      title,
      description,
      descriptionLink,
      reportId,
      powerBiPlaceholderImg,
      scrollable,
      slicerFilters,
      comingSoon,
      comingSoonImageUrl,
      altDownloadLink,
      descriptionBold,
      openGovReport,
      newTabLink,
      descriptionElement,
      reportContainerStyle,
      reportDescStyle,
      reportTitleStyle,
      onBackTap,
      onForwardTap,
      downloadTextContainerStyle,
      pageName,
    } = props;
    const [readMore, setReadMore] = useState(true);

    const toggleReadMore = () => {
      setReadMore(!readMore);
    };

    const handleOpenGovUrl = (url: string) => {
      if (url.includes("<iframe") && url.includes("</iframe>")) {
        const srcMatch = url.match(/src="([^"]+)"/);
        if (srcMatch && srcMatch[1]) {
          return srcMatch[1];
        }
      }
      return url;
    };

    return (
      <div
        ref={ref}
        className={`report-data-section app-padding ${reportContainerStyle}`}
        tabIndex={0}
      >
        <div className="report-text-container">
          <h1 className={`report-data-header ${reportTitleStyle}`}>{title}</h1>
          <div className="reportMobileNav">
            <div onClick={onBackTap} className="reportMobileNav__iconContainer">
              <CheveronLeft />
            </div>
            <h1 className="reportMobileNav__text">{title}</h1>
            <div
              onClick={onForwardTap}
              className="reportMobileNav__iconContainer"
            >
              <CheveronRight />
            </div>
          </div>
          <p
            style={{ overflowY: scrollable ? "auto" : "unset" }}
            className={` report-data-text ${reportDescStyle}`}
          >
            {typeof description === "string" &&
            description.length > 500 &&
            !scrollable
              ? readMore
                ? (description + " " + descriptionBold).slice(0, 500) + "..."
                : description
              : description}
            {descriptionElement && descriptionElement}
            {descriptionLink?.link && (
              <a
                href={descriptionLink.link}
                rel="noopener noreferrer"
                target="_blank"
                aria-label={`Link to ${descriptionLink.title}`}
              >
                {descriptionLink.title}
              </a>
            )}
            <b>{scrollable && (descriptionBold ? descriptionBold : "")}</b>
          </p>
          {!scrollable && (
            <p onClick={toggleReadMore} className="keep-reading-more-button">
              {typeof description === "string" && description.length > 500
                ? readMore
                  ? "Read more"
                  : "Read less"
                : null}
            </p>
          )}
          <p className="report-data-text report-note ">
            <span>Please note:</span> If the report is blank, no data has been
            reported by the {pageName}.
          </p>
        </div>
        {comingSoon || openGovReport === "" ? (
          <div className="coming-soon-container">
            <button className="coming-soon-button">COMING SOON</button>
            <img
              className="coming-soon-report-image"
              src={
                comingSoonImageUrl ??
                API_CONSTANTS.imageBucket +
                  "/powerbi-images/sco-report-image-6.jpg"
              }
            />
          </div>
        ) : (
          <div className="report-powerBi-container">
            {openGovReport ? (
              <div className="iframe-container">
                <h6>
                  {newTabLink && (
                    <a
                      href={handleOpenGovUrl(newTabLink)}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={`Link to ${title}`}
                    >
                      ACCESS DETAILED REPORT HERE
                    </a>
                  )}
                </h6>
                <iframe
                  src={handleOpenGovUrl(openGovReport)}
                  scrolling="no"
                ></iframe>
              </div>
            ) : (
              <>
                <div className="report-blank-container">
                  <WarningIcon />
                  <span>
                    If the report is blank, no data has been reported by the{" "}
                    {pageName}.
                  </span>
                </div>
                <LazyLoadPowerBi
                  reportId={reportId}
                  slicerFilters={slicerFilters}
                  placeholderImg={powerBiPlaceholderImg}
                />

                {altDownloadLink ? (
                  <div>
                    <a
                      href={altDownloadLink}
                      className="alt-download-link"
                      target={"_blank"}
                    >
                      <DownloadDataIcon iconSize={32} />
                      <span className="alt-download-report-data-text">
                        Due to file size, use this link to download the full
                        dataset.
                      </span>
                    </a>
                  </div>
                ) : (
                  <div
                    className={`download-data-container ${downloadTextContainerStyle}`}
                  >
                    <DownloadDataIcon iconSize={24} color={"#ffff"} />
                    <span className="download-report-data">
                      <span className="bold-text">To download data:</span> hover
                      on the visualization then click the three dots that appear
                      in the upper right of the report.
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default ReportSectionComponent;
