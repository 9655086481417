import React, { createContext, useContext, useState, ReactNode } from "react";

interface AppContextType {
  bannerHeight: number;
  setBannerHeight: (height: number) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [bannerHeight, setBannerHeight] = useState<number>(0);

  return (
    <AppContext.Provider value={{ bannerHeight, setBannerHeight }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
