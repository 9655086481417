import React, { forwardRef, useEffect, useRef } from "react";
import "./aboutSection.scss";

type AboutSectionProps = {
  imageUrl: string;
  imageAltText: string;
  title: string;
  description: string;
  dataInfo?: string;
  scrollDescriptionToElement?: string;
};

const AboutSection = forwardRef<HTMLDivElement, AboutSectionProps>(
  (
    {
      imageUrl,
      imageAltText,
      title,
      description,
      dataInfo,
      scrollDescriptionToElement,
    },
    ref
  ) => {
    const descriptionRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
      if (scrollDescriptionToElement && descriptionRef.current) {
        const linkElement = descriptionRef.current.querySelector(
          scrollDescriptionToElement
        );
        if (linkElement) {
          linkElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }, [scrollDescriptionToElement]);

    return (
      <div className="about-section" ref={ref}>
        <div className="description-container">
          <h1 className="title">{title}</h1>
          <p
            ref={descriptionRef}
            className="body-medium description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
          <p className="body-medium data-info">{dataInfo}</p>
        </div>
        <div>
          <img
            src={imageUrl}
            loading="lazy"
            className="image"
            alt={imageAltText}
            tabIndex={0}
            aria-label={`${imageAltText} image`}
          />
        </div>
      </div>
    );
  }
);

export default AboutSection;
