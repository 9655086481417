import { FC, ComponentType, SVGProps } from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { FileArrowDown } from "styled-icons/fa-solid";
import { ReportDoc } from "../../types/interfaces/entityInterfaces";
import CityIcon from "../../styles/icons/CityIcon";
import "./entityListCard.scss";

type entityListCardProps = {
  reportDocs?: ReportDoc[];
  entityName: string;
  actualExpenditures: string;
  actualRevenue: string;
  budgetedExpenditures: string;
  budgetedRevenue: string;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  noData?: boolean;
  isLoading?: boolean;
};

const EntityListCard: FC<entityListCardProps> = (
  props: entityListCardProps
) => {
  const {
    entityName,
    reportDocs,
    Icon,
    noData,
    isLoading,
    actualExpenditures,
    actualRevenue,
    budgetedExpenditures,
    budgetedRevenue,
  } = props;
  const breadcrumbs = useBreadcrumbs();

  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  const hasData =
    actualExpenditures !== null ||
    actualRevenue !== null ||
    budgetedExpenditures !== null ||
    budgetedRevenue !== null;

  const colClassName =
    actualExpenditures == null || budgetedExpenditures == null
      ? "col-2"
      : "col-3";

  const handleDisplayReportName = (report: ReportDoc) => {
    if (report.Version && Number(report.Version) > 2) {
      return `Amended ${report.Kind} ${Number(report.Version) - 1}`;
    } else if (report.Version) {
      return `Amended ${report.Kind}`;
    } else {
      return report.Kind;
    }
  };

  const LoadingDataCard = () => (
    <div className="entity-card-container no-entity">
      <p className="data-not-found body-large">LOADING DATA...</p>
    </div>
  );

  const NoDataCard = () => (
    <div className="entity-card-container no-entity">
      <p className="data-not-found body-large">NO DATA REPORTED</p>
    </div>
  );

  const ReportDocs = () => {
    return (
      <>
        {reportDocs ? (
          <span className="download-link-container">
            {reportDocs.map((report) => (
              <div
                key={report.ReportName + report.Version}
                className="download-link-container__row"
              >
                <FileArrowDown color="#3E5E94" size={24} />
                <a
                  href={report.Url}
                  target="_blank"
                  aria-label={`Link to download ${report.ReportName}`}
                  rel="noopener noreferrer"
                  className="download-link body-large"
                >
                  {handleDisplayReportName(report)}
                </a>
              </div>
            ))}
          </span>
        ) : (
          <br />
        )}
      </>
    );
  };

  return (
    <>
      {isLoading && <LoadingDataCard />}
      {!isLoading && noData && <NoDataCard />}
      {!isLoading && !noData && (
        <div className="entity-card-container">
          <div className="entity-icon-name-container">
            {breadcrumbs[1].key === "/City" ? (
              <CityIcon />
            ) : Icon ? (
              <Icon className="entity-icon" />
            ) : null}
            <p className="entity-name">{entityName}</p>
          </div>
          {hasData ? (
            <div className="data-type-info-container">
              <div className={colClassName}>
                <br />
                {actualExpenditures !== null && (
                  <p id="actual-label" className="data-type body-large">
                    Actual
                  </p>
                )}
                {budgetedRevenue !== null && (
                  <p id="budget-label" className="data-type body-large">
                    Budget
                  </p>
                )}
              </div>
              <div className={colClassName}>
                <p id="revenues-label" className="data-info-values body-large">
                  Revenues
                </p>
                {actualRevenue !== null && (
                  <p
                    aria-labelledby="actual-label revenues-label"
                    className="data-info-values body-large"
                  >
                    {USDollar.format(Number(actualRevenue))}
                  </p>
                )}
                {budgetedRevenue !== null && (
                  <p
                    aria-labelledby="budget-label revenues-label"
                    className="data-info-values body-large"
                  >
                    {USDollar.format(Number(budgetedRevenue))}
                  </p>
                )}
              </div>
              <div className={colClassName}>
                <p
                  id="expenditures-label"
                  className="data-info-values body-large"
                >
                  Expenditures
                </p>
                {actualExpenditures !== null && (
                  <p
                    aria-labelledby="actual-label expenditures-label"
                    className="data-info-values body-large"
                  >
                    {USDollar.format(Number(actualExpenditures))}
                  </p>
                )}
                {budgetedExpenditures !== null && (
                  <p
                    aria-labelledby="budget-label expenditures-label"
                    className="data-info-values body-large"
                  >
                    {USDollar.format(Number(budgetedExpenditures))}
                  </p>
                )}
              </div>
              <ReportDocs />
            </div>
          ) : (
            <div className="data-type-info-container no-data-grid">
              <div className="not-found-container">
                <p className="data-not-found body-large">
                  DATA NOT REPORTED BY ENTITY
                </p>
              </div>
              <ReportDocs />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EntityListCard;
