import { FC, useEffect, useState } from "react";
import {
  EntityListCard,
  SearchDropDownComponent,
  YearTagPill,
} from "../../../components";
import { BasicEntity } from "../../../types/interfaces/entityInterfaces";
import { getCounties } from "../../countiesLandingPage/countiesLandingPage.service";
import { requestEntityFinancials } from "../../../api/searchType.api";
import { CountyIcon } from "../../../styles/icons";

type CountiesEntityFinancialTableProps = {
  fiscalYears: string[];
  customTableClass?: string;
};

const CountiesEntityFinancialTable: FC<CountiesEntityFinancialTableProps> = ({
  fiscalYears,
  customTableClass = "",
}) => {
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<string>("2024");
  const [counties, setCounties] = useState<BasicEntity[]>([]);
  const [selectedCounty, setSelectedCounty] = useState<BasicEntity>();
  const [budgetReports, setBudgetReports] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tabIndexForPill, setTabIndexForPill] = useState(0);

  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    const init = async () => {
      const countyData = await getCounties();
      setCounties(countyData);
      setSelectedCounty(countyData[0]);
    };
    init();
  }, []);

  useEffect(() => {
    const handleGetBudgetFile = async () => {
      setIsLoading(true);
      if (selectedCounty?.id) {
        try {
          const entities = await requestEntityFinancials(
            selectedCounty?.id,
            selectedFiscalYear
          );

          setBudgetReports(entities);
          setIsLoading(false);
        } catch {
          setIsLoading(false);
          setBudgetReports([]);
        }
      }
    };
    handleGetBudgetFile();
  }, [selectedCounty, selectedFiscalYear]);

  return (
    <>
      <div className="pill-container">
        {fiscalYears?.map((year) => (
          <div
            tabIndex={tabIndexForPill}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                const targetElement = e.target as HTMLElement;
                const yearText =
                  targetElement.querySelector(".tag-pill")?.textContent;

                if (yearText) {
                  const year = yearText.replace("YEAR ", "");
                  setSelectedFiscalYear(year);
                }
                setTabIndexForPill(-1);
              }

              if (e.key === "Escape") {
                const activeEl = document.activeElement as
                  | HTMLElement
                  | SVGElement;
                if (activeEl?.blur) {
                  activeEl.blur();
                }
              }
            }}
            onBlur={() => setTabIndexForPill(0)}
          >
            <YearTagPill
              key={year}
              selectedYear={selectedFiscalYear}
              setSelectedYear={setSelectedFiscalYear}
              year={year}
            />
          </div>
        ))}
      </div>
      <h6 className="county-entity-search-menu-header">Search Entity</h6>
      <div className="search-component-container">
        <SearchDropDownComponent
          entitiesData={counties}
          selectedItem={selectedCounty}
          setSelectedItem={setSelectedCounty}
          isEntitiesFiltered={true}
          DefaultIcon={CountyIcon}
        />
      </div>

      <div className={`filter-table ${customTableClass}`}>
        {budgetReports?.length > 0 && !isLoading ? (
          budgetReports.map((report: any) => (
            <EntityListCard
              key={report.EntityID}
              isLoading={isLoading}
              entityName={report.EntityName}
              actualExpenditures={report.ExpenseActual}
              actualRevenue={report.RevenueActual}
              budgetedExpenditures={report.ExpenseBudget}
              budgetedRevenue={report.RevenueBudget}
              reportDocs={report.ReportDocs}
            />
          ))
        ) : (
          <EntityListCard
            key="0"
            noData
            entityName=""
            actualExpenditures={""}
            actualRevenue={""}
            isLoading={isLoading}
            budgetedExpenditures={""}
            budgetedRevenue={""}
          />
        )}
      </div>
    </>
  );
};

export default CountiesEntityFinancialTable;
