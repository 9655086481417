import { forwardRef } from "react";
import { QuickLinkIcon } from "../../styles/icons";
import "./quickLinksSection.scss";

type QuickLinksSectionProps = {
  links: QuickLinks[];
};

type QuickLinks = {
  imgSrc: string;
  imgAlt: string;
  title: string;
  pageUrl: string;
};
const QuickLinksSection = forwardRef<HTMLDivElement, QuickLinksSectionProps>(
  ({ links }, ref) => {
    return (
      <div className="quick-links-container" ref={ref}>
        <div className="links-title">
          <QuickLinkIcon className="icon" />
          <span>
            <h3>Quick Links</h3>
          </span>
        </div>

        <div className="links">
          {links.map(({ imgSrc, imgAlt, title, pageUrl }, index) => (
            <a
              className="navigate-link"
              href={pageUrl}
              key={title}
              target="_blank"
              aria-label={
                index === 0
                  ? `Quick-links section with five items. Link to ${title}`
                  : `Link to ${title}`
              }
            >
              <div className="link-container">
                <div className="link-image">
                  <img src={imgSrc} alt={imgAlt} />
                </div>
                <div className="title-container">
                  <h3>{title}</h3>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  }
);

export default QuickLinksSection;
