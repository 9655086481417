import { FC, memo } from "react";

const SewerWaterDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.08594 2.58203V23.4154H4.16927V14.0404H6.2526C6.2526 14.3166 6.36235 14.5816 6.5577 14.7769C6.75305 14.9723 7.018 15.082 7.29427 15.082H13.5443C13.8205 15.082 14.0855 14.9723 14.2808 14.7769C14.4762 14.5816 14.5859 14.3166 14.5859 14.0404H15.6276C15.9039 14.0404 16.1688 14.1501 16.3642 14.3455C16.5595 14.5408 16.6693 14.8058 16.6693 15.082V17.1654H22.9193V14.0404C22.9193 12.3828 22.2608 10.7931 21.0887 9.62095C19.9166 8.44885 18.3269 7.79037 16.6693 7.79037H14.5859C14.5859 7.5141 14.4762 7.24915 14.2808 7.0538C14.0855 6.85845 13.8205 6.7487 13.5443 6.7487H11.4609V4.66536H13.5443C13.8205 4.66536 14.0855 4.55562 14.2808 4.36027C14.4762 4.16492 14.5859 3.89997 14.5859 3.6237C14.5859 3.34743 14.4762 3.08248 14.2808 2.88713C14.0855 2.69178 13.8205 2.58203 13.5443 2.58203H7.29427C7.018 2.58203 6.75305 2.69178 6.5577 2.88713C6.36235 3.08248 6.2526 3.34743 6.2526 3.6237C6.2526 3.89997 6.36235 4.16492 6.5577 4.36027C6.75305 4.55562 7.018 4.66536 7.29427 4.66536H9.3776V6.7487H7.29427C7.018 6.7487 6.75305 6.85845 6.5577 7.0538C6.36235 7.24915 6.2526 7.5141 6.2526 7.79037H4.16927V2.58203H2.08594ZM12.5026 8.83203H8.33594V12.9987H12.5026V8.83203Z"
          fill="black"
        />
        <path
          d="M19.7943 17.7266L20.5547 18.5391L20.5557 18.5401L20.5578 18.5422L20.563 18.5474L20.5766 18.562C20.6408 18.6321 20.7027 18.7043 20.762 18.7786C20.8766 18.9172 21.0297 19.1161 21.1849 19.3516C21.4641 19.7755 21.8776 20.5078 21.8776 21.3026C21.8776 22.4411 20.9745 23.4172 19.7943 23.4172C18.6141 23.4172 17.7109 22.4411 17.7109 21.3026C17.7109 20.5078 18.1245 19.7755 18.4036 19.3516C18.5882 19.0748 18.7914 18.811 19.012 18.562L19.0266 18.5474L19.0307 18.5422L19.0328 18.5401L19.7943 17.7266Z"
          fill="black"
        />
      </svg>
    );
  }
);

export default SewerWaterDistrictIcon;
