import { FC, useEffect, useState, useCallback } from "react";
import {
  EntityListCard,
  SearchDropDownComponent,
  YearTagPill,
} from "../../../components";
import { handleTypeIcon } from "../../../types/enums/iconsEnum";
import { ENTITY_TYPE_IDS } from "../../../types/constants";
import {
  EntityFilter,
  EntityFinancialFilter,
} from "../../../types/interfaces/entityInterfaces";
import {
  getCountyEntityList,
  getCountyEntitiesByEntityTypeID,
} from "../../countyPage/countyPage.service";
import { getEntityTypeInCountyList } from "../localDistrictsLandingPage.service";

type EntityFinancialTableProps = {
  fiscalYears: string[];
};

const LocalDistrictEntityFinancialTable: FC<EntityFinancialTableProps> = ({
  fiscalYears,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<string>("2024");
  const [selectedEntity, setSelectedEntity] = useState<EntityFilter>();
  const [selectedCounty, setSelectedCounty] = useState<any>();
  const [entitiesForSearch, setEntitiesForSearch] = useState<EntityFilter[]>();
  const [countyEntities, setCountyEntities] = useState<any[]>([]);
  const [counties, setCounties] = useState<any[]>([]);
  const [tabIndexForPill, setTabIndexForPill] = useState(0);

  const fetchAndFilterCountyEntityList = useCallback(async () => {
    try {
      const res = await getCountyEntityList();
      if (res?.length) {
        const filteredEntities = res.filter(
          (entity: EntityFilter) =>
            entity.type === ENTITY_TYPE_IDS.ABATEMENT_DISTRICT
        );
        if (filteredEntities.length) {
          setSelectedEntity(filteredEntities[0]);
        }
        setEntitiesForSearch(filteredEntities);
      }
    } catch {
      console.error("Error fetching and filtering county entity list");
    }
  }, []);

  useEffect(() => {
    fetchAndFilterCountyEntityList();
  }, [fetchAndFilterCountyEntityList]);

  const fetchEntityTypeInCountyListCallback = useCallback(async () => {
    if (!selectedEntity) return;
    try {
      const res = await getEntityTypeInCountyList(
        selectedEntity.type || ENTITY_TYPE_IDS.ABATEMENT_DISTRICT
      );
      setCounties(res);
      setSelectedCounty(res[0]);
    } catch {
      console.error("Error fetching entity types");
    }
  }, [selectedEntity]);

  useEffect(() => {
    fetchEntityTypeInCountyListCallback();
  }, [fetchEntityTypeInCountyListCallback]);

  const fetchEntitiesByCountyCallback = useCallback(async () => {
    if (!selectedEntity?.type || !selectedCounty) return;
    setIsLoading(true);
    try {
      const res = await getCountyEntitiesByEntityTypeID(
        selectedEntity?.type,
        selectedCounty.id,
        selectedFiscalYear
      );
      const filteredEntities = res.map((entity: EntityFinancialFilter) => ({
        ...entity,
        reportDocs: entity.reportDocs?.filter((doc) => doc.Kind !== "Annual"),
      }));
      setCountyEntities(
        filteredEntities.sort((a, b) => a.name.localeCompare(b.name))
      );
    } catch {
      console.error("Error fetching entities by county");
    } finally {
      setIsLoading(false);
    }
  }, [selectedEntity, selectedCounty, selectedFiscalYear]);

  useEffect(() => {
    fetchEntitiesByCountyCallback();
  }, [fetchEntitiesByCountyCallback]);

  const fetchEntitiesForSearchCallback = useCallback(async () => {
    if (!selectedCounty) return;
    try {
      const res = await getCountyEntityList(selectedCounty.id);
      setEntitiesForSearch(res.sort((a, b) => a.name.localeCompare(b.name)));
    } catch {
      console.error("Error fetching entities for search");
    }
  }, [selectedCounty]);

  useEffect(() => {
    fetchEntitiesForSearchCallback();
  }, [fetchEntitiesForSearchCallback]);

  return (
    <>
      <div className="pill-container">
        {fiscalYears?.map((year) => (
          <div
            tabIndex={tabIndexForPill}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                const targetElement = e.target as HTMLElement;
                const yearText =
                  targetElement.querySelector(".tag-pill")?.textContent;

                if (yearText) {
                  const year = yearText.replace("YEAR ", "");
                  setSelectedFiscalYear(year);
                }
                setTabIndexForPill(-1);
              }

              if (e.key === "Escape") {
                const activeEl = document.activeElement as
                  | HTMLElement
                  | SVGElement;
                if (activeEl?.blur) {
                  activeEl.blur();
                }
              }
            }}
            onBlur={() => setTabIndexForPill(0)}
          >
            <YearTagPill
              key={year}
              year={year}
              selectedYear={selectedFiscalYear}
              setSelectedYear={setSelectedFiscalYear}
            />
          </div>
        ))}
      </div>
      <div className="dual-search-container">
        <div>
          <h6 className="ld-entity-search-subheader">Search Entity</h6>
          <div className="search-component-container">
            <SearchDropDownComponent
              entitiesData={entitiesForSearch}
              selectedItem={selectedEntity}
              setSelectedItem={setSelectedEntity}
              isEntitiesFiltered={true}
            />
          </div>
        </div>
        <div>
          <h6 className="ld-entity-search-subheader">Search by County</h6>
          <div className="search-component-container">
            <SearchDropDownComponent
              isEntitiesFiltered
              entitiesData={counties}
              selectedItem={selectedCounty}
              setSelectedItem={setSelectedCounty}
            />
          </div>
        </div>
      </div>
      <div className="filter-table">
        {countyEntities?.length ? (
          countyEntities?.map((data: EntityFinancialFilter) => (
            <EntityListCard
              key={data.id}
              isLoading={isLoading}
              Icon={handleTypeIcon(selectedEntity?.type)}
              entityName={data.name}
              actualExpenditures={data.expenseActual}
              actualRevenue={data.revenueActual}
              budgetedExpenditures={data.expenseBudget}
              budgetedRevenue={data.revenueBudget}
              reportDocs={data.reportDocs}
            />
          ))
        ) : (
          <EntityListCard
            noData
            entityName=""
            isLoading={isLoading}
            actualExpenditures={""}
            actualRevenue={""}
            budgetedExpenditures={""}
            budgetedRevenue={""}
          />
        )}
      </div>
    </>
  );
};

export default LocalDistrictEntityFinancialTable;
